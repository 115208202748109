<template>
  <!--  -->
  <el-dialog
    :model-value="dialogOrderDetail"
    width="900px"
    custom-class="dialogClass"
    :before-close="handleClose"
    top="5vh"
  >
    <div v-loading="loading" class="box">
      <el-row>
        <el-col :span="10" style="border-right: 1px solid rgba(0, 0, 0, 0.25)">
          <div class="num">{{ props.orderDetailData.number }}</div>
          <div class="sub">
            <div v-if="orderDetailData.platform_code !== 'local'">
              #{{ orderDetailData.platform_order_id }}({{
                orderDetailData.platform_code
              }})
            </div>
            <div v-else>None(Manual order)</div>
          </div>
        </el-col>
        <el-col :span="14" :push="1">
          <div style="display: flex" class="num">
            <div>${{ props.orderDetailData.total_price }}</div>
            <div
              class="round"
              :style="[
                { background: color },
                {
                  color:
                    props.orderDetailData.status_word === 'Canceled'
                      ? '#000'
                      : '#fff',
                },
              ]"
            >
              {{ props.orderDetailData.status_word }}
            </div>
          </div>
          <div class="sub">
            {{ formatDate(props.orderDetailData.updated_at) }}
          </div>
        </el-col>
        <!-- <el-button type="primary" round>{{
            props.orderDetailData.status_word
          }}</el-button> -->
      </el-row>
      <el-row style="padding-top: 30px">
        <el-col :span="24" class="message">
          99diy received this order and starts production, the product will be
          completed within 48 hours
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="18" class="order_item">
          <el-table
            :data="orderItemData"
            style="width: 100%"
            :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
          >
            <el-table-column label="Product">
              <template #default="{ row }">
                <el-row :gutter="10">
                  <el-col :span="4">
                    <div class="order_img">
                      <el-image :src="row.img" alt=""> </el-image>
                      <div class="previewImage">
                        <img
                          :src="row.img"
                          alt=""
                          style="width: 100%; height: 100%"
                        />
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="20">
                    <div class="order_item_name">{{ row.name }}</div>
                    <div class="order_item_sub">
                      <span v-for="(v, key) in row.options" :key="key">
                        <span style="margin-right: 10px">
                          {{ row.options[key] }}
                        </span>
                      </span>
                    </div>
                    <template v-if="!!row.print && !!row.print.materials">
                      <div
                        v-for="(value, key) in row.print.materials"
                        :key="key"
                        style="margin-bottom: 10px"
                      >
                        <template
                          v-if="
                            !!row.print.materials[key] &&
                            Array.isArray(row.print.materials[key]) &&
                            row.print.materials[key].length > 0
                          "
                        >
                          <div style="font-weight: 600">{{ key }}</div>

                          <div
                            v-for="(val, index) in row.print.materials[key]"
                            :key="index"
                          >
                            <template v-if="!!val.name">
                              <span style="font-weight: 600">{{
                                val.name
                              }}</span>
                              <span> : </span>
                              <div
                                v-if="val.type === 'image'"
                                class="setImageBox"
                              >
                                <div
                                  v-for="(item, index) in setImage(val)"
                                  :key="index"
                                >
                                  <div class="order_img">
                                    <!-- <el-image
                                      :src="item"
                                      class="setImage"
                                    ></el-image> -->
                                    <div class="setImage">
                                      <a  >{{ item }}</a>
                                    </div>
                                    <div
                                      class="previewImage"
                                      style="height: auto"
                                    >
                                      <img
                                        :src="item"
                                        alt=""
                                        style="width: 100%; height: 100%;display: block;"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <span v-else>{{ val.value }}</span>
                            </template>
                          </div>
                        </template>
                      </div>
                      <!-- <div
                        class="order_item_sub"
                        v-for="(item, index) in row.print.materials"
                        :key="index"
                      >
                        {{ item.name }} : {{ item.value }}
                      </div> -->
                    </template>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column
              prop="total_qty"
              label="Qty"
              width="70"
              align="center"
            />
            <el-table-column
              prop="product_price"
              :label="'Cost ( ' + props.orderItem + ' items )'"
              width="120"
              align="center"
            >
              <template #default="scope">
                ${{ scope.row.product_price }}
              </template>
            </el-table-column>
            <el-table-column
              prop="total_price"
              label="Toal"
              width="90"
              align="center"
              header-align="center"
            >
              <template #default="scope">
                ${{ scope.row.total_price }}
              </template>
            </el-table-column>
          </el-table>
          <!-- 价格 -->
          <div class="price">
            <div class="price_name">Subtotal</div>
            <div class="price_num">${{ orderDetailData.product_price }}</div>
          </div>
          <div class="price">
            <div class="price_name">
              Shipping
              <span
                >({{ orderDetailData.transport_limitation || 0 }} business days
                after fulfillment)</span
              >
            </div>
            <!-- {{orderDetailData}} -->
            <div class="price_num">${{ orderDetailData.ship_price }}</div>
          </div>
          <div class="price">
            <!-- <div class="price_name">Coupon <span>(MSKSDFQ)</span></div> -->
            <div class="price_name">Coins</div>
            <div class="price_num">
              - ${{ orderDetailData.discount_integrate }}
            </div>
          </div>
          <div class="price">
            <!-- <div class="price_name">Coupon <span>(MSKSDFQ)</span></div> -->
            <div class="price_name">
              Coupon
              <span v-if="!!orderDetailData.coupon_code"
                >({{ orderDetailData.coupon_code }})</span
              >
            </div>
            <div class="price_num">
              - ${{ orderDetailData.discount_coupons }}
            </div>
          </div>
          <div class="price">
            <div class="price_name">Total</div>
            <div class="price_num">${{ orderDetailData.total_price }}</div>
          </div>
          <!-- 描述 -->
          <div class="des">
            * Our product prices can change depending on where the order is
            fulfilled and which currency you use to pay for it. For example, if
            you order a t-shirt that we only fulfill in the US and you pay for
            it in EUR, the price you pay would be our USD price converted to
            EUR.
          </div>

          <!-- 物流信息 -->
          <div class="history">
            <div class="history_title">Option history</div>
            <!-- 物流步骤条 -->
            <el-steps
              :active="1"
              direction="vertical"
              space="100px"
              align-center
            >
              <el-step
                v-for="(item, index) in optionHistorys"
                :key="index"
                :title="formatDate(item.created_at)"
                :description="item.description"
              >
                <template #icon>
                  <i class="iconfont icon-round"></i>
                </template>
              </el-step>
            </el-steps>
          </div>
        </el-col>
        <el-col :span="6" class="ship">
          <!-- 配送地址 -->
          <div class="ship_title">
            <div>Shipping Address</div>
            <div
              class="ship_edit"
              @click="setEditAddress"
              v-if="editStatus(props.orderDetailData.status_word)"
            >
              Edit
            </div>
          </div>
          <div class="ship_info">{{ orderDetailData.first_name }}</div>
          <div class="ship_info">{{ orderDetailData.address1 }}</div>
          <div class="ship_info">{{ orderDetailData.address2 }}</div>
          <div class="ship_info">
            {{ orderDetailData.city }}, {{ orderDetailData.zip }}
          </div>
          <div class="ship_info">{{ orderDetailData.country }}</div>
          <div class="ship_info">{{ orderDetailData.phone }}</div>

          <template
            v-if="
              !!orderDetailData.ship_code &&
              !!orderDetailData.trackUrl &&
              !!orderDetailData.ship_number
            "
          >
            <div class="logistics_info">Tracking Information</div>
            <div class="ship_info">
              Shipping Carrier:
              {{ orderDetailData.ship_code }}
            </div>
            <a
              class="fulfillment_number"
              target="_blank"
              :href="orderDetailData.trackUrl"
              >{{ orderDetailData.ship_number }}</a
            >
          </template>

          <div class="btn_box">
            <!-- 确定订单 -->
            <el-button
              type="primary"
              class="btn"
              v-if="props.orderDetailData.status_word === 'Unpaid'"
              :loading="loadingShow"
              @click="confirmOrder(orderDetailData.number)"
              >Confirm order</el-button
            >

            <!-- 复制订单 -->
            <el-button
              type="primary"
              :loading="duplicateOrderCopy"
              class="btn"
              @click="duplicateOrder(orderDetailData.number)"
              >Duplicate order</el-button
            >

            <!-- 取消订单 -->
            <div
              v-if="
                props.orderDetailData.status_word === 'Unpaid' ||
                props.orderDetailData.status_word === 'Paid'
              "
            >
              <el-popconfirm
                title="Are you sure to delete this?"
                @confirm="cancelOrder(orderDetailData.number)"
              >
                <template #reference>
                  <el-button type="info" class="btn">Cancel order</el-button>
                </template>
              </el-popconfirm>
            </div>

            <!-- 删除订单 -->
            <div v-if="props.orderDetailData.status_word === 'Canceled'">
              <el-popconfirm
                title="Are you sure to delete this?"
                @confirm="handleDelOrder(orderDetailData.number)"
              >
                <template #reference>
                  <el-button type="info" class="btn">Delete order</el-button>
                </template>
              </el-popconfirm>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <EditAddress
      :editAddressShow="editAddressShow"
      @close="editAddressShow = $event"
      :orderDetailData="orderDetailData"
      @updateDetail="updateDetail"
    />
  </el-dialog>
</template>

<script setup>
import { deleteOrder, cancelOrderNumber, copyOrder } from "@/api/myStore.js";
import EditAddress from "./editAddress.vue";
import { ElMessage } from "element-plus";
import { defineProps, defineEmits, ref, watch, computed } from "vue";
import { payByPaypal } from "@/api/common.js";
const props = defineProps({
  orderDetailData: {
    type: Object,
    default: () => ({}),
  },
  orderItemData: {
    // 订单详情条目数据
    type: Array,
    default: () => [],
  },
  orderItem: {
    // 订单条目数量
    type: Number,
    default: 0,
  },
  optionHistorys: {
    // 订单操作历史
    type: Array,
    default: () => [],
  },
  dialogOrderDetail: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

let handleClose = () => {
  emit("close", false);
};

//EditAddress

let editAddressShow = ref(false);

let editStatus = computed(() => {
  return (e) => {
    return e === "Unpaid";
    // || e === 'Paid'|| e === 'In production'
  };
});

let setImage = computed(() => {
  return (e) => {
    if (e.type === "image") {
      let values = e.value.split(",");
      return values;
    }
  };
});

let setEditAddress = () => {
  editAddressShow.value = true;
  console.log(props.orderDetailData);
};

let updateDetail = (e) => {
  emit("update");
  emit("setUpdateDetail", e);
};

//状态颜色
let color = ref("");
watch(
  () => props.orderDetailData.status_word,
  () => {
    if (
      props.orderDetailData.status_word === "Unpaid" ||
      props.orderDetailData.status_word === "In Production" ||
      props.orderDetailData.status_word === "Paid"
    ) {
      color.value = "#FF7700";
    } else if (props.orderDetailData.status_word === "Canceled") {
      color.value = "#E5E5E5";
    } else if (
      props.orderDetailData.status_word === "In Transit" ||
      props.orderDetailData.status_word === "Delivered"
    ) {
      color.value = "#1EAB7F";
    }
  }
);

const emit = defineEmits([
  "update",
  "input",
  "closePopup",
  "close",
  "setUpdateDetail",
]);

// 时间格式化方法
let formatDate = (time) => {
  const date = new Date(time);
  let dateString = date.toDateString();
  const timeString = date.toLocaleTimeString("en-US");
  dateString = dateString.split(" ").slice(1).join(" ");
  return dateString + " " + timeString;
};

//删除订单
let handleDelOrder = async (num) => {
  try {
    const { message } = await deleteOrder(num);
    ElMessage({
      message: message,
      type: "success",
    });
    emit("update");
    emit("input", false);
  } catch (error) {
    ElMessage.error(error);
  }
};

//确定订单
let loadingShow = ref(false);
let confirmOrder = async (num) => {
  // loadingShow.value = true;
  // try {
  //   const { data } = await payByPaypal({
  //     orderNumbers: num,
  //   });
  //   // window.open(data.approveUrl);

  //   window.location.href = data.approveUrl;
  //   emit("update");
  //   emit("input", false);
  // } catch (error) {
  //   ElMessage.error(error);
  // }
  // loadingShow.value = false;
  emit("closePopup", props.orderDetailData.total_price, num);
};

//取消订单
let cancelOrder = async (orderNumber) => {
  try {
    const { message } = await cancelOrderNumber({
      orderNumber,
    });
    emit("update");
    emit("input", false);
    ElMessage({
      message: message,
      type: "success",
    });
  } catch (error) {
    ElMessage.error(error);
  }
};

//复制订单
let duplicateOrderCopy = ref(false);
let duplicateOrder = async (num) => {
  duplicateOrderCopy.value = true;
  try {
    const { message } = await copyOrder(num);
    emit("update");
    emit("input", false);
    ElMessage({
      message: message,
      type: "success",
    });
  } catch (e) {
    ElMessage.error(e);
  }
  duplicateOrderCopy.value = false;
};
</script>

<style lang="scss" scoped>
:deep(.el-row) {
  position: inherit;
}
:deep(.el-table__cell),
:deep(.el-table),
:deep(.el-table__body-wrapper),
:deep(.el-row) {
  position: inherit;
}

.setImageBox {
  display: flex;
  flex-wrap: wrap;
}

.setImage {
  // width: 80px;
  height: auto;
  margin-top: 8px;
  margin-right: 5px;
}

.order_img:hover .previewImage {
  display: block;
}

.previewImage {
  width: 300px;
  height: 300px;
  background-color: #fff;
  position: absolute;
  top: 50px;
  left: -320px;
  display: none;
  border-radius: 5px;
  overflow: hidden;
}

.fulfillment_number {
  color: #1eab7f;
}

.logistics_info {
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}

.box {
  margin-top: -30px;
  height: 750px;
  overflow-y: auto;
  overflow-x: hidden;
}
:deep(.el-table .cell) {
  padding: 0;
}
:deep(.el-step__title.is-finish),
:deep(.el-step__description.is-finish) {
  color: #000;
}
.num {
  height: 40px;
  line-height: 40px;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}
.sub {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}
.message {
  margin-top: -20px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
}
.price {
  display: flex;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &_name {
    span {
      margin-left: 5px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
.des {
  margin: 20px 0;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}
.history {
  &_title {
    height: 60px;
    line-height: 60px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.ship {
  padding: 0 30px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 8px;
  &_title {
    // height: 36px;
    line-height: 15px;
    padding: 5px 0;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  &_info {
    // height: 36px;
    line-height: 20px;
    padding: 5px 0;
  }
  &_edit {
    cursor: pointer;
    color: #1eab7f;
    margin-right: -20px;
  }
  .btn_box {
    .btn {
      width: 110%;
      text-align: center;
      margin: 10px 0px;
    }
  }
}
.order_item {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  img {
    width: 52px;
    height: 52px;
  }
  &_sub {
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.45);
  }
}
.green {
  background: #1eab7f;
  border: none;
}
.yellow {
  background: #ff7700;
  border: none;
}
.round {
  height: 34px;
  background: #ff7700;
  border-radius: 17px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  padding: 0 20px;
  margin-left: 20px;
}

.icon-round {
  font-size: 22px;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
  //margin-right: 5px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: transparent;
}

@media screen and (max-width: 1530px) {
  .previewImage {
    left: 370px;
    top: 195px;
  }
}
</style>

<script setup>
import { defineProps, ref, defineEmits, computed, nextTick, watch } from "vue";
import { MoreFilled, View, Edit, WarningFilled } from "@element-plus/icons";
import { ElMessageBox, ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import {
  finishedProduct,
  deleteShopifyProduct,
  copyFinishedProduct,
} from "@/api/myStore";
import CreateOrder from "../createOrder/createOrder.vue";
import { useStore } from "vuex";
import CopyProducts from "./copyProducts.vue";
import { publishedShopifyProducts, getRebootDesign } from "@/api/design.js";
import damaged from "@/assets/error/damaged.png";
import Creating from "@/assets/product/Creating.gif";
import BillingMethod from "@/components/billingMethod.vue";
import { encode } from "js-base64";
import Store from "store";
import DownloadImg from "@/components/downloadImg.vue";

let imgHide = computed(() => {
  return (row) => {
    // return !!row.img && row.img.length > 0 && Array.isArray(row.img);
    return Number(row.image_merge_status) === 1;
  };
});

const props = defineProps({
  tableData: Array,
  showLoading: Boolean,
  storeCode: String,
  itemCode: String,
  platform: String | undefined,
  clearSelectTable: Boolean,
});

const emit = defineEmits(["selection", "update", "setShowLoading"]);

let update = () => {
  emit("update");
};

let multipleTableRef = ref("");
watch(
  () => props.clearSelectTable,
  () => {
    if (!!props.clearSelectTable) {
      multipleTableRef.value.clearSelection();
    }
  }
);

let handleSelectionChange = (e) => {
  emit("selection", e);
};
// 删除成品
let handleDelete = (item) => {
  ElMessageBox.confirm(
    "Are you sure you want to delete this product?",
    "Warning",
    {
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      type: "Delete",
    }
  )
    .then(async () => {
      try {
        const { message } = await finishedProduct({
          designId: item.id,
        });
        emit("update");
        ElMessage({
          type: "success",
          message: message,
        });
      } catch (error) {
        ElMessage.error(error);
      }
    })
    .catch(() => {
      return false;
    });
};

//复制店铺
let copy = async (id) => {
  try {
    const { message } = await copyFinishedProduct({
      designId: [id],
      storeCode: [props.itemCode || props.storeCode],
    });

    emit("update");
    ElMessage({
      type: "success",
      message: message,
    });
  } catch (error) {
    ElMessage.error(error);
  }
};

//隐藏shopify
let hideShopify = (val) => {
  ElMessageBox.confirm(
    "This action will remove this product from your Shopify store. Are you sure you want to continue?",
    "Are you sure?",
    {
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      type: "warning",
    }
  )
    .then(async () => {
      emit("setShowLoading");
      try {
        const { message } = await deleteShopifyProduct({
          designId: val.id,
          topic: 1,
        });
        emit("update");
        ElMessage({
          type: "success",
          message: message,
        });
      } catch (error) {
        ElMessage.error(error);
      }
    })
    .catch(() => {
      return false;
    });
};
//复制产品到...店铺
let copyStoreShow = ref(false);
let storeId = ref(null);
let copyStore = (id) => {
  copyStoreShow.value = true;
  storeId.value = id;
};

// 跳转设计器
let editDesign = (item) => {
  //回调地址
  let path = encode(encodeURIComponent(window.location.href));

  //token
  let token;
  if (process.env.VUE_APP_CUSTOM_ENV === "development") {
    token = process.env.VUE_APP_TOKEN;
  } else {
    token = Store.get("diyToken");
  }

  // 地址
  let url = process.env.VUE_APP_TEMPLATE_DESIGN;

  const { id, user_code, store_code, product_id } = item;
  let openUrl = `${url}?productId=${product_id}&designId=${id}&backUrl=${path}&token=${token}&storeCode=${store_code}&merchantId=${user_code}`;
  console.log(openUrl);
  window.location.href = openUrl;
};

//创建订单
let orderId = ref(null);
let createOrderShow = ref(false);
let createOrder = (row) => {
  createOrderShow.value = true;
  orderId.value = row.id;
};

let close = () => {
  createOrderShow.value = false;
};

const router = useRouter();

//编辑产品
let editListing = (item) => {
  const { id, page } = item;
  //获取分页page
  sessionStorage.setItem("designPage", page);
  //获取浏览器高度
  nextTick(() => {
    let top =
      document.body.scrollTop ||
      document.documentElement.scrollTop ||
      window.pageYOffset;
    sessionStorage.setItem("designTop", Number(top));
  });
  router.push(`/store/productEdit/${id}`);
};

const store = useStore();
const codeID = computed(() => store?.state?.user?.userInfo?.codeID);
// 推送产品到第三方平台
let publish = async (e) => {
  e.loading = true;
  try {
    const { message } = await publishedShopifyProducts({
      designId: e.id,
      // storeCode: props.itemCode || props.storeCode,
    });
    let messageHtml = `
    <span>${message}<span/>
    <img src="https://www.shareasale.com/sale.cfm?tracking=${codeID.value}&amount=0.00&merchantID=126376&transtype=lead" width="1" height="1">
    `;
    ElMessage({
      dangerouslyUseHTMLString: true,
      type: "success",
      message: messageHtml,
    });
    emit("update");
  } catch (e) {
    ElMessage.error(e);
  }
  e.loading = false;
};

let billingMethodShow = ref(false);
let sum = ref("0.00");
let orderNumbers = ref("");
let closePopup = (e, v) => {
  sum.value = e;
  orderNumbers.value = v;
  billingMethodShow.value = true;
  createOrderShow.value = false;
};

let updateList = () => {
  createOrderShow.value = false;
  billingMethodShow.value = false;
};

//失效产品禁用复选框
let selectable = (row) => {
  return !!row.status;
};

//失效产品加上透明度
let cellStyle = ({ row }) => {
  if (!row.status) {
    return "opacity: .5;";
  }
};

//下载图片
let downloadShow = ref(false);
let imgList = ref([]);
let downloadImg = (e) => {
  downloadShow.value = true;
  imgList.value = e.design_colors.map((e) => {
    e.show = false;
    return e;
  });
};

//下载图片弹窗关闭
let downloadClose = () => {
  downloadShow.value = false;
};

//生成失败 重新生成

let regenerate = async (e) => {
  e.regenerateShow = true;
  try {
    const { message } = await getRebootDesign({
      designId: e.id,
    });
    ElMessage.success(message);
    emit("update");
  } catch (e) {
    ElMessage.error(e || "Serve Error");
  }
  e.regenerateShow = false;
};

let setImgUrl = computed(() => {
  return (item) => {
    if (item?.thumb_img?.img400) {
      return item?.thumb_img?.img400;
    } else {
      return item.img[0].img400;
    }
  };
});
</script>

<template>
  <el-table
    :show-header="false"
    :data="tableData"
    style="width: 100%; margin-top: 20px"
    v-loading="showLoading"
    element-loading-text="Loading..."
    @selection-change="handleSelectionChange"
    :selectable="selectable"
    :cell-style="cellStyle"
    ref="multipleTableRef"
  >
    <el-table-column type="selection" width="45" :selectable="selectable" />
    <el-table-column label="img" width="120">
      <template #default="scope">
        <el-image
          v-if="
            Number(scope.row.image_merge_status) === 1 &&
            scope.row.img &&
            scope.row.img.length > 0 &&
            Array.isArray(scope.row.img)
          "
          :src="setImgUrl(scope.row)"
        >
          <template #error>
            <!-- <el-image :lazy="true" fit="cover" :src="damaged"></el-image> -->
            <div class="Creating_box">
              <el-icon :size="30" color="#FF8484"><WarningFilled /></el-icon>
              <div class="Loading_Failed">Loading Failed</div>
            </div>
          </template>
        </el-image>

        <div
          v-else-if="
            Number(scope.row.image_merge_status) === 0 ||
            Number(scope.row.image_merge_status) === 2
          "
          class="Creating_box"
        >
          <el-image
            :src="Creating"
            fit="contain"
            class="Creating_img"
          ></el-image>
          <div class="Creating_text">Creating...</div>
        </div>

        <el-image v-else :src="damaged"> </el-image>
      </template>
    </el-table-column>
    <el-table-column min-width="410">
      <template #default="scope" class="content">
        <div :style="{ 'opacity: .5;': scope.row.status === 0 }">
          <div class="content_name">{{ scope.row.name }}</div>
          <div class="flex" style="margin-bottom: 5px">
            <div class="content_des">
              <span style="margin-right: 3px"
                >{{ scope.row.item.length }} Variants
              </span>
              <span style="padding-right: 5px">·</span>

              <span>All in stock</span>
            </div>
            <div
              class="personalizedProduct"
              v-if="
                Number(scope.row.type) === 3 || Number(scope.row.type) === 2
              "
            >
              Personalized Product
            </div>
          </div>
          <div class="flex">
            <!-- <div v-if="scope.row.is_issue === 1" class="content_link">
              Published
            </div>
            <div v-else class="content_des" style="margin-top: 0">
              Unpublished
            </div> -->
            <div :title="'Remark:' + scope.row.remark" class="flex">
              <div style="flex: 1">Remark:</div>
              <div v-if="!!scope.row.remark" class="remark">
                {{ scope.row.remark }}
              </div>
              <span v-else>none</span>
            </div>
            <div
              class="activity"
              v-if="
                !!scope.row.product &&
                !!scope.row.product.market_activity &&
                !!scope.row.product.market_activity.status
              "
            >
              Free shipping on orders over ${{
                scope.row.product.market_activity.satisfy_price
              }}
            </div>
          </div>
          <div class="failure" v-if="scope.row.status === 0">
            <i class="iconfont icon-Invalid"></i>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column min-width="362">
      <template #default="{ row }">
        <div class="btn_box">
          <!-- <el-button
            v-if="Number(row.image_merge_status) === 2"
            @click="regenerate(row)"
            :loading="row.regenerateShow"
            >Re synthesis</el-button
          > -->
          <!-- v-else -->
          <el-button
            type="primary"
            v-if="row.is_issue === 0 && row.status === 1 && !!platform"
            :disabled="!imgHide(row) || Number(row.status) === 0"
            @click="publish(row)"
            :loading="row.loading"
            class="padding-15"
            >Publish</el-button
          >
          <el-button
            type="primary"
            @click="createOrder(row)"
            :disabled="!imgHide(row) || Number(row.status) === 0"
            class="padding-15"
            >Create Order</el-button
          >
          <el-button
            plain
            @click="editListing(row)"
            :disabled="!imgHide(row) || Number(row.status) === 0"
            class="padding-15"
            >Edit listing</el-button
          >
        </div>
      </template>
    </el-table-column>
    <el-table-column min-width="50" align="left">
      <template #default="{ row }">
        <el-dropdown trigger="click" placement="bottom-start">
          <el-icon size="20">
            <MoreFilled />
          </el-icon>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                @click="editDesign(row)"
                v-if="
                  imgHide(row) && Number(row.status) === 1 && row.type === 3
                "
                >Edit design</el-dropdown-item
              >
              <el-dropdown-item
                @click="copy(row.id)"
                v-if="imgHide(row) && Number(row.status) === 1"
                >Copy</el-dropdown-item
              >
              <el-dropdown-item
                @click="copyStore(row.id)"
                v-if="imgHide(row) && Number(row.status) === 1"
                >Copy to ...</el-dropdown-item
              >

              <el-dropdown-item
                @click="hideShopify(row)"
                v-if="
                  imgHide(row) &&
                  row.is_issue === 1 &&
                  Number(row.status) === 1 &&
                  !!platform
                "
                >Hide on {{ platform }}</el-dropdown-item
              >
              <!-- <el-dropdown-item
                @click="createOrder(row)"
                v-if="imgHide(row) && Number(row.status) === 1"
                >Create order</el-dropdown-item
              > -->
              <el-dropdown-item
                @click="downloadImg(row)"
                v-if="imgHide(row) && Number(row.status) === 1"
                >Download</el-dropdown-item
              >
              <el-dropdown-item
                @click="handleDelete(row)"
                v-if="row.is_issue === 0 || Number(row.status) === 0"
                >Delete</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>

  <!-- //创建订单 -->
  <CreateOrder
    v-model="createOrderShow"
    @input="close"
    :orderId="orderId"
    @closePopup="closePopup"
  />

  <BillingMethod
    v-model="billingMethodShow"
    :sum="sum"
    :orderNumbers="orderNumbers"
    @updateList="updateList"
    @close="billingMethodShow = $event"
  />

  <!-- 复制产品 -->
  <CopyProducts
    v-model="copyStoreShow"
    @input="copyStoreShow = $event"
    :storeId="storeId"
    @update="update"
  />

  <!-- //下载图片 -->
  <DownloadImg
    :downloadShow="downloadShow"
    :imgList="imgList"
    @close="downloadClose"
  />
</template>

<style lang="scss" scoped>
.remark {
  width: 350px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}
.padding-15 {
  padding-right: 15px;
  padding-left: 15px;
}
.activity {
  margin-left: 20px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 20px;
  padding: 1px 14px;
}
.failure {
  position: absolute;
  right: 10px;
  top: 50%;
  text-align: center;
  line-height: 40px;
  .icon-Invalid {
    font-size: 60px;
    font-weight: 400;
    background: #fff;
  }
}
.personalizedProduct {
  line-height: 35px;
  margin-left: 15px;
  color: rgba(0, 0, 0, 0.85);
  background: #e5e5e5;
  padding: 0 10px;
  border-radius: 35px;
  margin-top: 10px;
  font-size: 12px;
}
.spance {
  justify-content: space-between;
}
.Creating_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 22px 0;
  .Creating_img {
    width: 26px;
    height: 30px;
    object-fit: contain;
  }
  .Creating_text,
  .Loading_Failed {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 20px;
    margin-top: 12px;
  }
  .Loading_Failed {
    color: #ff8484;
  }
}
:deep(tr.el-table__row) {
  height: 112px;
}
:deep(.el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title) {
  border-bottom: #000;
  background: #000;
}
:deep(.el-button) {
  min-height: 20px;
}
:deep(.el-dropdown-menu__item) {
  font-size: 14px;
  padding: 0 15px;
}
:deep(.el-dropdown) {
  cursor: pointer;
}
:deep(.el-table-column--selection .cell) {
  text-align: center;
}
.img {
  width: 70px;
  height: 70px;
  border: 1px solid #707070;
}
.content {
  &_name {
    width: 100%;
    font-weight: bold;
    word-break: break-word;
    color: rgba(0, 0, 0, 0.85);
  }
  &_des {
    font-size: 14px;
    font-weight: 400;
    margin: 15px 0 0 0;
  }
  &_link {
    font-size: 14px;
    font-weight: 400;
    &_btn {
      padding: 0;
      height: 20px;
      color: rgba(0, 0, 0, 0.65);
      text-decoration: underline;
    }
  }
}
.btn_box {
  text-align: right;
}
</style>

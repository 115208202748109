<script setup>
import { defineProps, ref, defineEmits, reactive, computed } from "vue";
import { CirclePlus } from "@element-plus/icons";
import selectProduct from "./selectProduct.vue";
import AddAddress from "./addAddress.vue";
import DetermineLogistics from "./determineLogistics.vue";
import {
  selectFinishedProductList,
  finishedProductDetails,
} from "@/api/myStore.js";
import { ElMessage } from "element-plus";
import damaged from "@/assets/error/damaged.png";
import { integrateDiscount } from "@/api/common.js";
import BigNumber from "bignumber.js";

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  orderId: Number | Array,
  //店铺code
  itemCode: {
    type: String,
    default: null,
  },
  //店铺code
  storeCode: {
    type: String,
    default: null,
  },
});

let addProductsShow = ref(false);

const emit = defineEmits(["input", "closePopup"]);

let setPrice = computed(() => (price, sizePrice, num, id) => {
  let prices = (Number(sizePrice) + Number(price)).toFixed(2);

  determineProductList.list.forEach((item) => {
    if (item.id === id) {
      item.totalPrice = ((Number(sizePrice) + Number(price)) * num).toFixed(2);
    }
  });
  return prices;
});

let setTotal = computed(() => {
  let total = determineProductList.list.reduce(
    (previousValue, currentValue) =>
      previousValue + Number(currentValue.totalPrice),
    0
  );
  return total.toFixed(2);
});

//打开弹窗的回调
let open = () => {
  clearData.value = false;

  productList();
  addProductsShow.value = true;
};

let product = reactive({ list: [] });
let meta = reactive({ obj: {} });
let page = ref(1);
//获取成品列表
let loading = ref(false);
let productList = async () => {
  let arr = [];
  if(Array.isArray(props.orderId)) {
    arr = props.orderId
  } else {
   !!props.orderId && arr.push(props.orderId)
  }
  

  loading.value = true;
  try {
    const { data } = await selectFinishedProductList({
      storeCode: props.itemCode || props.storeCode,
      page: page.value,
      perPage: 15,
      designId: arr,
    });
    data?.data?.forEach((e) => {
      e.design_colors.forEach((v) => {
        v.design_item.forEach((el) => {
          el.show = false;
          el.num = 1;
          el.price = e.product.price;
          el.factory_code = e.product.factory_code;
          el.weight = el.size.weight;
          el.site_code = e.product.site_code;
          el.mininum = e.product.minimum;
          el.market_activity = e.product.market_activity;
          el.type = Number(e.type);

          v.specification = e.product.specification;
          // || Number(e.type) === 3
          if (Number(e.type) === 2 || Number(e.type) === 3) {
            el.design_edit_special = JSON.parse(
              JSON.stringify(e.design_edit_special)
            );
          }
        });
      });
    });
    meta.obj = data?.meta?.pagination;
    product.list = data?.data?.filter(
      (val) => val.img.length > 0 && val.product.is_sale === 1
    );
  } catch (error) {
    console.log(error);
    ElMessage.error(error.message || error);
  }
  loading.value = false;
};

let state = ref(false);

let clearData = ref(false);

//清空数据
let handleClose = (e) => {
  clearData.value = true;
  emit("input", e);
  addProductsShow.value = false;
  step2.value = false;
  step3.value = false;
  addAddressShow.value = false;
  determineLogisticsShow.value = false;
  determineProductList.list = [];
  // productArr.value = [];
  product.list = [];
  state.value = false;
  page.value = 1;
  // flag.value = false
  statusUS.value = false;
  statusAU.value = false;
  confirmShow.value = false;
};

//添加产品
let clearShowFlase = ref(false);
let addProducts = () => {
  addProductsShow.value = true;
  clearShowFlase.value = true;
  confirmShow.value = false;
};

let clearShow = () => {
  clearShowFlase.value = false;
};

// let flag = ref(false);
let determineProductList = reactive({ list: [] });
let determineSelectProduct = (val) => {
  if (!!Number(val)) {
    //过滤 删除
    determineProductList.list = determineProductList.list.filter(
      (item) => Number(item.id) !== Number(val)
    );
    statusUS.value = false;
    statusAU.value = false;
  } else {
    //添加

    //工厂国家
    if (!!val.FactoryEnName) {
      val.FactoryState = val.FactoryEnName.slice(2, 4);
    }

    determineProductList.list.push(val);
  }

  // 获取工厂code   美国工厂跟澳洲工厂同时存在不能下单
  determineProductList.list.forEach((item) => {
    if (item.FactoryState === "US") {
      statusUS.value = item.FactoryState === "US";
    } else if (item.FactoryState === "AU") {
      statusAU.value = item.FactoryState === "AU";
    }
  });
};

//删除产品
let deleteProductId = ref(null);
let deleteProduct = (id) => {
  deleteProductId.value = id;
  determineProductList.list = determineProductList.list.filter((item) => {
    if (Number(item.id) === Number(id)) {
      item.show = false;
    }

    return Number(item.id) !== Number(id);
  });

  statusUS.value = false;
  statusAU.value = false;
};

//确定产品
let step2 = ref(false);
let step3 = ref(false);
let addAddressShow = ref(false);
let integrateDeduction = ref(0.0); //使用多少积分抵扣多少金额
let expendIntegrate = ref(0); //使用多少积分
let calculateWeight = ref(0); //产品重量
let statusUS = ref(false);
let statusAU = ref(false);
let continueWithShipping = async () => {
  // 获取工厂code   美国工厂跟澳洲工厂同时存在不能下单
  determineProductList.list.forEach((item) => {
    if (item.FactoryState === "US") {
      statusUS.value = item.FactoryState === "US";
    } else if (item.FactoryState === "AU") {
      statusAU.value = item.FactoryState === "AU";
    }
  });

  if (statusUS.value && statusAU.value) {
    return false;
  }

  // 获取产品价格总计

  if (!!setTotal.value && Number(setTotal.value) > 0) {
    step2.value = true;
    addAddressShow.value = true;

    // //获取产品数量
    // let sum = determineProductList.list.reduce(
    //   (previousValue, currentValue) => previousValue + Number(currentValue.num),
    //   0
    // );
    //获取 产品id 产品数量 产品重量 产品价格
    let arr = [];
    determineProductList.list.forEach((e) => {
      let price = new BigNumber(e.price)
        .plus(new BigNumber(e.size.price))
        .times(new BigNumber(e.num)); //总价格 = (产品价格 + size价格) * 数量
      let totalWeight = new BigNumber(e.weight).times(new BigNumber(e.num)); //总重量  = 产品重量 * 数量
      let obj = {
        id: e.product_id,
        totalQuantity: e.num,
        totalPrice: price.toFixed(2),
        totalWeight: totalWeight.toFixed(2),
      };
      arr.push(obj);
    });

    const map = {};
    arr.forEach((item) => {
      if (map[item.id]) {
        map[item.id].totalQuantity = new BigNumber(map[item.id].totalQuantity)
          .plus(new BigNumber(item.totalQuantity))
          .toFixed(0);
        map[item.id].totalPrice = new BigNumber(map[item.id].totalPrice)
          .plus(new BigNumber(item.totalPrice))
          .toFixed(2);
        map[item.id].totalWeight = new BigNumber(map[item.id].totalWeight)
          .plus(new BigNumber(item.totalWeight))
          .toFixed(2);
      } else {
        map[item.id] = item;
      }
    });
    arr = Object.values(map);
    try {
      const { data } = await integrateDiscount({
        product: arr,
      });
      integrateDeduction.value = Number(data?.integrateDeduction);
      expendIntegrate.value = Number(data?.expendIntegrate);
      calculateWeight.value = Number(data?.calculateWeight);
    } catch (e) {
      ElMessage.error(e);
    }
  }
};

//从填选地址退回选择产品
let backSetProduct = () => {
  step2.value = false;
  addAddressShow.value = false;
};

//确定地址
let addressInfo = reactive({ obj: {} });
let determineLogisticsShow = ref(false);
// let productArr = ref([]);
let determineAddress = (formData) => {
  addressInfo.obj = formData;
  step3.value = true;
  determineLogisticsShow.value = true;
  // productArr.value = determineProductList.list;
};

//从物流信息回退到确定地址
let back = () => {
  determineLogisticsShow.value = false;
  step3.value = false;
};

let closePopup = (sum, orderNumbers) => {
  emit("closePopup", sum, orderNumbers);
  handleClose();
};

let changePage = (e) => {
  page.value = e;
  productList();
};

let setfactory = computed(() => {
  return (e) => {
    if (e.FactoryState === "AU") {
      return "( Ships to the AUS only )";
    } else if (e.FactoryState === "US") {
      return "( Ships to the USA only )";
    }
  };
});

let closeAddProductsShow = () => {
  addProductsShow.value = false;
};

let confirmShow = ref(false);
let confirm = (e) => {
  confirmShow.value = e;
};
</script>

<template>
  <el-dialog
    :model-value="value"
    width="995px"
    top="10vh"
    :before-close="handleClose"
    :close-on-click-modal="false"
    @open="open"
  >
    <template #title>
      <span style="font-size: 24px">Create order</span>
    </template>
    <!-- 流程表 -->
    <div class="flowChart">
      <div class="active">1.Add products</div>
      <div class="color_text" :class="{ active: step2 }">
        2.Add shipping infomation
      </div>
      <div class="color_text" :class="{ active: step3 }">3.Confirm order</div>
    </div>
    <!-- 流程一 选择产品 -->
    <div v-show="!addAddressShow">
      <div v-show="!addProductsShow">
        <div class="addProducts" @click="addProducts">
          <el-icon size="15" class="addIcon"> <CirclePlus /></el-icon>
          Add from created products
        </div>
        <div class="products_box">
          <div
            class="flex img_box"
            v-for="item in determineProductList.list"
            :key="item.id"
          >
            <div class="flex">
              <div class="el_img">
                <el-image :src="item.img[0].img400" style="width: 100%; height: 100%">
                  <template #error>
                    <el-image :lazy="true" fit="cover" :src="damaged">
                    </el-image>
                  </template>
                </el-image>
                <div class="previewImage">
                  <el-image
                    :src="item.img[0].img400"
                    style="width: 100%; height: 100%"
                  ></el-image>
                </div>
              </div>
              <div class="img_text">
                <div class="img_title">{{ item.name }}</div>
                <div class="font400 flex">
                  <div class="size_color">
                    {{ item.size_name }} · {{ item.color_name }}
                  </div>
                  <div
                    class="activity"
                    v-if="
                      !!item.market_activity && !!item.market_activity.status
                    "
                  >
                    Free shipping on orders over ${{
                      item.market_activity.satisfy_price
                    }}
                  </div>
                </div>
                <!-- <div>USD {{ setPrice(item.size.price) }}</div> -->
                <div v-if="!!item.FactoryEnName" class="font400">
                  Factory Code：{{ item?.FactoryEnName }}
                  <span style="color: #ff2379"> {{ setfactory(item) }}</span>
                </div>
              </div>
            </div>
            <div class="rightArrow">
              <div>
                USD
                {{ setPrice(item.price, item.size.price, item.num, item.id) }}
              </div>
              <div style="margin: 0 20px">
                <el-input-number
                  v-model="item.num"
                  :step="1"
                  :min="!!item.mininum ? item.mininum : 1"
                  step-strictly
                  size="small"
                  title
                />
              </div>
              <div @click="deleteProduct(item.id)">
                <i
                  class="icon-shejiqi-shanchu iconfont"
                  style="color: rgba(51, 51, 51, 0.45)"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="addProductsShow">
        <selectProduct
          @back="addProductsShow = $event"
          @determineSelectProduct="determineSelectProduct"
          :deleteProductId="deleteProductId"
          :product="product"
          :loading="loading"
          :clearData="clearData"
          :state="state"
          :meta="meta"
          @changePage="changePage"
          :clearShowFlase="clearShowFlase"
          @clearShow="clearShow"
          @closeAddProductsShow="closeAddProductsShow"
          @confirm="confirm"
        />
      </div>
    </div>

    <!-- 流程二 填选地址 -->
    <div v-show="addAddressShow && !determineLogisticsShow">
      <AddAddress
        @backSetProduct="backSetProduct"
        @determineAddress="determineAddress"
        :clearData="clearData"
        :statusUS="statusUS"
        :statusAU="statusAU"
      />
    </div>

    <!-- 流程三 确定物流以及信息 -->
    <div v-if="determineLogisticsShow">
      <DetermineLogistics
        :productArr="determineProductList.list"
        :addressInfo="addressInfo"
        @back="back"
        @close="handleClose"
        :integrateDeduction="integrateDeduction"
        :expendIntegrate="expendIntegrate"
        @closePopup="closePopup"
        @deleteProductId="deleteProduct"
        @updateIntegral="continueWithShipping"
        :calculateWeight="calculateWeight"
      />
    </div>
    <!-- // 底部 -->
    <!-- 流程一 选择产品 -->
    <span class="dialog-footer" v-show="!addAddressShow">
      <div class="flex flex_end">
        <div class="Subtotal">Subtotal</div>
        <div style="margin-top: 20px">USD {{ setTotal }}</div>
      </div>
      <div class="flex" :class="statusUS && statusAU ? 'spance' : 'flex_end'">
        <div
          style="
            width: 600px;
            color: red;
            line-height: 40px;
            padding-left: 20px;
          "
          v-show="statusUS && statusAU"
        >
          Products from different factories cannot be submitted together
        </div>
        <el-button
          type="primary"
          @click="continueWithShipping"
          :disabled="statusUS && statusAU"
        >
          Continue with shipping
        </el-button>
      </div>
    </span>
  </el-dialog>
</template>

<style scoped lang="scss">
.font400 {
  font-weight: 400;
}
.spance {
  justify-content: space-between;
}
:deep(span.el-dialog__title) {
  font-size: 30px !important;
}
.color_text {
  color: #00000040;
}
.active {
  color: #000000d9 !important;
}
.products_box {
  margin-top: 15px;
  height: 306px;
  background: rgba(255, 255, 255, 0.39);
  // box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  overflow: auto;
  .img_box {
    padding: 0 20px;
    justify-content: space-between;
    height: 102px;
    cursor: pointer;
    &:hover {
      background: #00000008;
    }
    .rightArrow {
      display: flex;
      line-height: 100px;
    }
    .el_img {
      width: 72px;
      height: 72px;
      margin-top: 15px;
      border: 1px solid #707070;
      &:hover .previewImage {
        display: block;
      }
      .previewImage {
        position: absolute;
        top: 200px;
        left: -320px;
        width: 300px;
        height: 300px;
        background-color: #707070;
        z-index: 2;
        border-radius: 5px;
        overflow: hidden;
        display: none;
      }
    }
    .img_text {
      margin-top: 18px;
      margin-left: 20px;
      .img_title {
        width: 500px;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.85);
      }
      .activity {
        margin-left: 20px;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.14);
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 20px;
        padding: 1px 14px;
        margin-bottom: 4px;
      }
      .size_color {
        line-height: 24px;
      }
    }
  }
}
.flex_end {
  justify-content: flex-end;
}
.flex {
  display: flex;
}
.Subtotal {
  margin-right: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.flowChart {
  display: flex;
  justify-content: space-evenly;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}
.addProducts {
  height: 40px;
  background: #0000000d;
  line-height: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  margin-top: 30px;
  cursor: pointer;
  user-select: none;
  .addIcon {
    margin: 0 10px 0 20px;
  }
}

@media screen and (max-width: 1650px) {
  .previewImage {
    left: 125px !important;
    // left: 420px !important;
    // top: 186px !important;
    top: 190px !important;
  }
}
</style>

<script setup>
import { reactive, ref, computed,watch } from "vue";
import defaultStore from "@/assets/storeImg/defaultStore.png";

import $store from "@/store/index";
import { ElMessage } from "element-plus";
import { setDefaultStore } from "@/api/myStore";
import { useRouter } from "vue-router";


const emit = defineEmits(["viewStore"]);

const props = defineProps({
  storeData:{
    type:Array,
    default:()=>([])
  },
  loading:{
    type:Boolean,
    default:false
  }
})


watch(()=>props.storeData,()=>{
  console.log(props.storeData);
})

// 店铺详情
let viewStore = async (item) => {
  // $store.commit("setStoreCode", item.code);
  sessionStorage.setItem("setStoreCode", item.code);
  emit("viewStore", item);
  try {
    await setDefaultStore({
      storeCode: item.code,
    });
    // $store.dispatch("store/storeListAction");
  } catch (error) {
    ElMessage.error(error);
  }
};

let router = useRouter();
// 链接shopify
const handleGoPage = () => {
  // window.location.href = 'https://apps.shopify.com/print-on-demand-99diy'
  // window.open("https://apps.shopify.com/print-on-demand-99diy");
  router.push("/store/addNewStore");
};
</script>

<template>
  <div class="loading" v-loading="!loading" element-loading-text="Loading...">
    <el-row v-for="item in storeData" :key="item.code" style="margin-top: 20px">
      <el-col :span="24">
        <el-card shadow="always">
          <el-row>
            <el-col :span="2">
              <div style="width: 60px; height: 60px">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item.is_authorize === 0 ? defaultStore : item.logo"
                  alt="logo"
                />
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <div class="margin-b name">
                  {{ item.name }}
                  {{ item.is_default === 1 ? "( default )" : "" }}
                </div>
                <!-- <el-button
                  :type="item.is_authorize === 1 ? 'success' : 'info'"
                  :style="
                    item.is_authorize === 1 ? '' : 'color: rgba(0,0,0,0.65)'
                  "
                  round
                  size="mini"
                  >{{ item.is_authorize_word }}</el-button
                > -->
                <div class="btn_authorize" :class="item.is_authorize === 1 ? 'color_fff' : 'color_e5'" >
                  {{ item.is_authorize_word }}
                </div>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <div class="margin-b">
                  {{ item.designs_count || 0 }}
                </div>
                <div class="text">Products</div>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <div class="margin-b">
                  {{ item.new_order_number || 0 }} /
                  {{ item.total_order_number || 0 }}
                </div>
                <div class="text">New / All Orders</div>
              </div>
            </el-col>
            <el-col :span="8" class="btn-group">
              <el-button
                v-if="item.is_authorize === 0"
                type="primary"
                @click="handleGoPage"
                >Connect</el-button
              >
              <el-button
                type="primary"
                @click="viewStore(item)"
                class="viewStore"
                >View store</el-button
              >
              <el-button
                class="setting"
                type="info"
                @click="$router.push(`/store/settings/${item.code}?state=0`)"
                >Setting</el-button
              >
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
.color_fff {
  color:#fff;
  background-color: #67c23a;
}
.color_e5 {
  color:rgba(0,0,0,0.65);
  background-color: #e5e5e5;
}
.btn_authorize {
  display:inline-block;
  padding: 5px 20px;
  border-radius: 40px;
}
.name {
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.margin-b {
  margin-bottom: 13px;
}
.btn-group {
  margin: 10px 0 0 0;
  text-align: right;
}
.text {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
}

@media screen and (max-width: 1350px) {
  .setting {
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 1200px) {
  .viewStore {
    margin-top: 10px !important;
  }
}
</style>

<script setup>
import { ref, onMounted, reactive, computed } from "vue";
import CopyrightInfo from "@/components/copyrightInfo.vue";
import StoreList from "./components/storeList/storeList.vue";
import StoreDetails from "./components/storeDetails/storeDetails.vue";
import { useRoute, useRouter } from "vue-router";
import { getStoreList, getPlatformList } from '@/api/myStore.js'
import { ElMessage } from 'element-plus'
import $store from "@/store/index";

let route = useRoute();
// let storeData = ref([]);
// let loading = ref(false);
$store.dispatch("store/storeListAction");

let storeData = reactive([]);
let loading = ref(false);
//店铺list
storeData = computed(() => $store.state.store.storeList);
//店铺list loading
loading = computed(() => $store.state.store.loading);




let getStoreData = async () => {


//   loading.value = false
//   try {
//     const { data } = await getStoreList();

//     const res = await getPlatformList();

//     res.data.forEach((item) => {
//       data.forEach((sub) => {
//         if (item.code === sub.platform_code) {
//           sub.logo = item.logo;
//         }
//       });
//     });
//     storeData.value = data


//   } catch (e) {
//     console.log(e);
//     ElMessage.error(e);
//   }
//   loading.value = true
};


onMounted(() => {
  getStoreData()
  let code = sessionStorage.getItem("setStoreCode");
  if (code || Number(route.query.state)) {
    storeListShow.value = true;
    storeCode.value = code;
  } else {
    storeListShow.value = false;
  }

  if (!!route.query.storeCode) {
    storeCode.value = route.query.storeCode;
    sessionStorage.setItem("setStoreCode", route.query.storeCode);
    storeListShow.value = true;
  }
});

let storeListShow = ref(false); //显示状态
let storeCode = ref(""); //店铺详情code
let storeId = ref(""); //店铺详情id

let viewStore = (val) => {
  storeCode.value = val.code;
  storeId.value = val.id;
  storeListShow.value = true;
};

//返回店铺list
let managerMyStore = () => {
  storeListShow.value = false;
  sessionStorage.removeItem("setStoreCode");
};

let routerLink = useRouter();
let addNewStore = () => {
  routerLink.push("/store/addNewStore");
};
</script>

<template>
  <div class="store_box">
    <!-- 店铺列表 -->
    <template v-if="!storeListShow">
      <div class="w1120">
        <!-- 标题 -->
        <div class="store_text">
          <div class="store">My Stores</div>
          <el-button type="primary" class="btn" @click="addNewStore"
            >Add new store</el-button
          >
        </div>

        <!-- 分割线 -->
        <el-divider></el-divider>

        <!-- 店铺 -->
        <StoreList
          @viewStore="viewStore"
          :storeData="storeData"
          :loading="loading"
        />
      </div>
    </template>

    <!-- //店铺详情 -->
    <template v-else>
      <StoreDetails
        :storeCode="storeCode"
        :storeId="storeId"
        @managerMyStore="managerMyStore"
        :storeData="storeData"
      />
    </template>

    <!-- 底部 -->
    <div class="foot">
      <copyright-info></copyright-info>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/views/myAccount/account.scss";
.foot {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 99;
  transform: translate(-50%);
}
.store_box {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  font-family: PingFang SC;
  // display: flex;
  height: calc(100vh - 65px);
  // flex-direction: column;
  // justify-content: space-between;
  .w1120 {
    padding: 50px 0 40px 0;
    width: 1120px;
    margin: 0 auto;
  }

  .store_text {
    display: flex;
    justify-content: space-between;
  }
  .store {
    font-size: 36px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }
  .btn {
    text-align: right;
    font-size: 16px;
  }
}
</style>

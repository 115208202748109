<script setup>
import JSZip from "jszip";
import FileSaver from "file-saver";
import axios from "axios";
import {} from "vue";

const props = defineProps({
  downloadShow: {
    type: Boolean,
    default: false,
  },
  imgList: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["close"]);

let handleClose = () => {
  emit("close");
};

let downloadImg = (e) => {
  if (!e.show) {
    downloadItem(e);
  }
};

let downloadItem = (item) => {
  let imgList = [];
  console.log(item);
  //不是数组
  if (!!item) {
    item.img.forEach((val) => {
      imgList.push(val.img);
    });
  }

  item.show = true;
  const zip = new JSZip();
  const cache = {};
  const promises = [];
  imgList.forEach((item) => {
    const promise = getFile(item).then((data) => {
      const arr_name = item.split("/"); // 下载文件, 并存成ArrayBuffer对象
      const file_name = arr_name[arr_name.length - 1]; // 获取文件名
      zip.file(file_name, data, { binary: true }); // 逐个添加文件
      cache[file_name] = data;
    });
    promises.push(promise);
  });
  Promise.all(promises).then(() => {
    zip
      .generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: { level: 1 },
      })
      .then((content) => {
        // 生成二进制流
        FileSaver.saveAs(content, `${item.name}.zip`); // 利用file-saver保存文件  自定义文件名
        item.show = false;
      });
  });
};

let getFile = (url) => {
  return new Promise((resolve, reject) => {
    let obj = {
      method: "get",
      url,
      // responseType: 'blob'
      responseType: "arraybuffer",
    };
    axios(obj)
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error.toString());
      });
  });
};
</script>

<template>
  <el-dialog
    v-model="downloadShow"
    title="Download"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="title">
      <div>Image</div>
      <div>Color</div>
      <div>Operation</div>
    </div>
    <div class="overflowAuto">
      <div class="content" v-for="item in imgList" :key="item.id">
        <div>
          <el-image :alt="item.name" :src="item.img[0].img400"></el-image>
        </div>
        <div>
          {{ item.name }}
        </div>
        <div @click="downloadImg(item)" class="downloadImg">
          <el-icon size="25" color="#ff2379" v-if="!item.show"
            ><Download
          /></el-icon>
          <el-icon size="25" color="#ff2379" v-else class="is-loading">
            <Loading />
          </el-icon>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped lang="scss">
::deep(.el-icon) {
  font-size: 20px !important;
}
.title {
  display: flex;
  justify-content: space-around;
  font-size: 15px;
  color: #333;
  height: 50px;
  line-height: 50px;
  background: #f7f7f7;
  & > div {
    text-align: center;
    width: 100px;
  }
}
.overflowAuto {
  height: 420px;
  overflow: auto;
}
.content {
  height: 120px;
  display: flex;
  justify-content: space-around;
  line-height: 120px;
  color: #333;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 0;
  & > div {
    line-height: 100px;
    width: 100px;
    text-align: center;
  }
  .downloadImg {
    cursor: pointer;
  }
}
</style>

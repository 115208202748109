<script setup>
import { reactive, onMounted, ref, watch } from "vue";
import { getWordArea } from "@/api/common.js";
import { updateDeliveryAddress } from "@/api/myStore.js";
import { ElMessage } from "element-plus";
let props = defineProps({
  editAddressShow: {
    type: Boolean,
    default: () => false,
  },
  orderDetailData: {
    type: Object,
    default: () => ({}),
  },
});

let emit = defineEmits(["close", "updateDetail"]);

let open = () => {
  formData.fullName = props.orderDetailData.first_name;
  formData.phone = props.orderDetailData.phone;
  formData.country = props.orderDetailData.country;
  formData.zip = props.orderDetailData.zip;
  formData.address1 = props.orderDetailData.address1;
  formData.address2 = props.orderDetailData.address2;
  formData.state = props.orderDetailData.state;
  formData.city = props.orderDetailData.city;

  if (formData.country) {
    countryChange(formData.country, true);
  }
  if (formData.state) {
    stateChange(formData.state, true);
  }
};

let handleClose = () => {
  emit("close", false);
  formData.fullName = "";
  formData.phone = "";
  formData.country = "";
  formData.countryName = "";
  formData.stateName = "";
  formData.state = "";
  formData.cityName = "";
  formData.city = "";
  formData.zip = "";
  formData.address1 = "";
  formData.address2 = "";
  form.value.resetFields();
  list.states = []
  list.cities = []
};

onMounted(() => {
  wordArea();
});

//获取地址
let list = reactive({
  country: [],
  states: [],
  cities: [],
});
let wordArea = async () => {
  try {
    const { data } = await getWordArea();
    list.country = data;
  } catch (error) {
    ElMessage.error(error);
  }
};

let formData = reactive({
  fullName: "",
  phone: "",
  country: "",
  countryName: "",
  stateName: "",
  state: "",
  cityName: "",
  city: "",
  zip: "",
  address1: "",
  address2: "",
});

let rules = reactive({
  fullName: {
    required: true,
    message: "Please select activity resource",
    trigger: "blur",
  },
  phone: {
    required: true,
    message: "Please select activity resource",
    trigger: "blur",
  },
  country: {
    required: true,
    message: "Please select activity resource",
    trigger: "change",
  },
  state: {
    required: true,
    message: "Please select activity resource",
    trigger: "change",
  },
  city: {
    required: true,
    message: "Please select activity resource",
    trigger: "change",
  },
  zip: {
    required: true,
    message: "Please select activity resource",
    trigger: "blur",
  },
  address1: {
    required: true,
    message: "Please select activity resource",
    trigger: "blur",
  },
  // address2: {
  //   required: true,
  //   message: "Please select activity resource",
  //   trigger: "blur",
  // },
});

//选中国家时
let countryChange = (code, falg) => {
  if (!falg) {
    formData.state = "";
    formData.city = "";
  }
  list.country.forEach((item) => {
    if (item.code === code) {
      formData.countryName = item.name;
      list.states = item.states;
    }
  });
};

//选中省时
let stateChange = (id, falg) => {
  if (!falg) {
    formData.city = "";
  }

  list.states.forEach((item) => {
    if (item.name === id) {
      formData.stateName = item.name;
      list.cities = item.cities;
    }
  });
};

//确定地址
const form = ref(null);
let determineAddress = () => {
  form.value.validate(async (val) => {
    if (val) {
      try {
        const { message } = await updateDeliveryAddress({
          fullName: formData.fullName,
          phone: formData.phone,
          stateOrProvince: formData.state,
          city: formData.city,
          postal: formData.zip,
          address1: formData.address1,
          address2: formData.address2,
          orderNumber: props.orderDetailData.number,
        });
        handleClose();
        emit("updateDetail", props.orderDetailData.number);
        ElMessage.success(message);
      } catch (e) {
        ElMessage.error(e);
      }
    }
  });
};

//城市
const querySearch = (queryString, cb) => {
  const results = queryString
    ? list.cities.filter(createFilter(queryString))
    : list.cities;
  // call callback function to return suggestions
  cb(results);
};
const createFilter = (queryString) => {
  return (restaurant) => {
    return (
      restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
    );
  };
};

//选择城市
// let cityChange = (val) => {
//   list.cities.forEach((item) => {
//     if (item.name === val.id) {
//       formData.cityName = item.name;
//     }
//   });
// };
</script>

<template>
  <el-dialog
    :model-value="editAddressShow"
    title="Edit Shipping Address"
    width="700px"
    @open="open"
    :append-to-body="true"
    :before-close="handleClose"
  >
    <el-form
      :model="formData"
      ref="form"
      inline
      label-position="top"
      :rules="rules"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Full name" prop="fullName">
            <el-input
              v-model="formData.fullName"
              placeholder="Full name"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Phone" prop="phone">
            <el-input v-model="formData.phone" placeholder="phone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Country" prop="country">
            <el-select
              disabled
              v-model="formData.country"
              @change="countryChange"
              filterable
            >
              <el-option
                :label="item.name"
                :value="item.code"
                v-for="item in list.country"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="State / Province" prop="state">
            <el-select
              v-model="formData.state"
              @change="stateChange"
              filterable
            >
              <el-option
                :label="item.name"
                :value="item.name"
                v-for="item in list.states"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--  -->
        <el-col :span="12">
          <el-form-item label="City">
            <!-- <el-select v-model="formData.city" @change="cityChange" filterable >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in list.cities"
                :key="item.id"
              ></el-option>
            </el-select> -->
            <el-autocomplete
              v-model="formData.city"
              :fetch-suggestions="querySearch"
              class="inline-input"
              placeholder="Please Input"
              value-key="name"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Postal / Zip Code" prop="zip">
            <el-input
              v-model="formData.zip"
              placeholder="Postal / Zip Code"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Detiled Address" prop="address1">
            <el-input
              v-model="formData.address1"
              placeholder="Please enter detiled Address"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="24">
          <el-form-item label="Address 2 (optional)">
            <el-input
              v-model="formData.address2"
              placeholder="Address 2 (optional)"
            ></el-input>
          </el-form-item>
        </el-col> -->
      </el-row>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="determineAddress">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
:deep(.el-select),
:deep(.el-autocomplete) {
  width: 100%;
}
:deep(.el-form-item__label) {
  height: 30px;
  line-height: 30px;
}
:deep(.el-form-item) {
  margin-bottom: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>

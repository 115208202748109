<script setup>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  defineProps,
  onMounted,
  watch,
  defineEmits,
} from 'vue'
import SearchInput from '../searchInput.vue'
import { ElMessage } from 'element-plus'
import { getOrderList, getOrderDetail, getOrderStatus } from '@/api/myStore.js'
import { Top } from '@element-plus/icons'
import OrderDetail from './orderDetail.vue'
import BillingMethod from '@/components/billingMethod.vue'

let sortValue = ref('')

let tagList = ref([])
let isCheck = ref(0)
let tableLoading = ref(false)

// 下拉框排序选项
let sortOptions = reactive([
  {
    value: '1',
    label: 'Recenfly edited',
  },
  {
    value: '2',
    label: 'Recenfly created',
  },
])

const props = defineProps({
  storeCode: String,
  itemCode: String | Number,
  createOrderShow: {
    type: Boolean,
    default: false,
  },
  updateState: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['setUpdateShow', 'createOrder'])

//店铺变化
watch(
  () => props.itemCode,
  (newVal, oldVal) => {
    orderStatus()
    orderList()
  }
)

watch(
  () => [props.createOrderShow, props.updateState],
  () => {
    if (!props.createOrderShow || props.updateState) {
      orderStatus()
      orderList()
    }
  }
)

onMounted(() => {
  orderStatus()
  orderList()
})

//回到顶部
let toTop = () => {
  let top = document.documentElement.scrollTop || document.body.scrollTop
  // 实现滚动效果
  const timeTop = setInterval(() => {
    document.body.scrollTop = document.documentElement.scrollTop = top -= 50
    if (top <= 0) {
      clearInterval(timeTop)
    }
  }, 10)
}

//获取标签状态
let orderStatus = async () => {
  try {
    const { data } = await getOrderStatus(props.itemCode || props.storeCode)
    const name = Object.keys(data)
    const count = Object.values(data)
    tagList.value = name.map((item, i) => ({ name: item, count: count[i] }))
  } catch (error) {
    ElMessage.error(error)
  }
}

//获取订单list
let dataShow = ref(false)
let tableData = ref([])
let meta = ref({})
let page = ref(1)
let perPage = ref(15)
let orderList = async () => {
  tableLoading.value = true
  try {
    const { data } = await getOrderList({
      page: page.value,
      perPage: perPage.value,
      storeCode: props.itemCode || props.storeCode,
      status: status.value === 0 ? status.value : status.value || undefined,
      sort: sort.value || undefined,
      orderNumber: orderNumber.value || undefined,
    })
    tableData.value = data?.data.map((val) => {
      //状态颜色
      if (
        val.status_word === 'Unpaid' ||
        val.status_word === 'In production' ||
        val.status_word === 'Paid'
      ) {
        val.color = '#FF7700'
      } else if (val.status_word === 'Canceled') {
        val.color = '#E5E5E5'
      } else if (
        val.status_word === 'In Transit' ||
        val.status_word === 'Delivered'
      ) {
        val.color = '#1EAB7F'
      }
      return val
    })
    meta.value = data?.meta
    tableLoading.value = false
    if (tableData.value.length < 0) {
      dataShow.value = true
    }
    // emit("setUpdateShow");
  } catch (error) {
    ElMessage.error(error)
  }
}

//分页
let handleCurrentChange = (e) => {
  page.value = e
  orderList()
}

//标签状态改变
let status = ref(null)
let filterTag = (e) => {
  let statusMap = {
    // 状态映射
    Unpaid: 0,
    Paid: 1,
    Canceled: 5,
    'In Production': 10,
    'In Transit': 20,
    Delivered: 30,
  }

  status.value = statusMap[e]
  orderList()
}

//sortChange 下拉框状态
let sort = ref(null)
let sortChange = (e) => {
  if (Number(e) === 1) {
    //最近编辑
    sort.value = 'updated_at'
  } else if (Number(e) === 2) {
    //最近创建
    sort.value = 'created_at'
  } else {
    sort.value = ''
  }
  orderList()
}

//搜索
let orderNumber = ref('')

let changeInput = (e) => {
  orderNumber.value = e
  orderList()
}

//更新详情
let updateDetail = (e) => {
  setUnpay({ number: e })
}

// 获取订单详情
let loading = ref(false)
let orderDetailData = ref({}) // 订单详情数据
let orderItemData = ref([]) // 订单详情条目数据
let orderItem = ref(0) // 订单条目数量
let optionHistorys = ref([]) // 订单操作历史
let dialogOrderDetail = ref(false)
let setUnpay = async (row) => {
  loading.value = true
  dialogOrderDetail.value = true
  try {
    const { data } = await getOrderDetail(row.number)
    orderDetailData.value = data
    orderItemData.value = data.order_items
    orderItem.value = data.order_items.length
    optionHistorys.value = data.order_historys
  } catch (error) {
    ElMessage.error(error.message)
  }
  loading.value = false
}

// 创建订单
const createOrder = () => {
  emit('createOrder')
}
//更新
let update = () => {
  orderList()
  orderStatus()
}

let billingMethodShow = ref(false)
let sum = ref('0.00')
let orderNumbers = ref('')
let closePopup = (e, v) => {
  sum.value = e
  orderNumbers.value = v
  billingMethodShow.value = true
  dialogOrderDetail.value = false
}

let updateList = () => {
  update()
  billingMethodShow.value = false
}

let closeDetai = ()=>{
  dialogOrderDetail.value = false
}
</script>

<template>
  <SearchInput
    @changeInput="changeInput"
    placeholder="Order No. / Your store order No."
  />
  <!-- 筛选标签 -->
  <el-row style="margin-top: 20px; margin-bottom: 20px">
    <el-col :span="20">
      <div class="tag_box">
        <el-check-tag
          :checked="isCheck === 0"
          @click="filterTag(null), (isCheck = 0)"
          >All</el-check-tag
        >
        <template v-for="item in tagList" :key="item.name">
          <el-check-tag
            v-if="item.name !== 'all'"
            :checked="isCheck === item.name"
            @click="filterTag(item.name), (isCheck = item.name)"
          >
            {{ item.name }} · {{ item.count }}
          </el-check-tag>
        </template>
      </div>
    </el-col>
    <el-col :span="4">
      <el-select
        v-model="sortValue"
        placeholder="Select"
        @change="sortChange"
        clearable
      >
        <el-option
          v-for="item in sortOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-col>
  </el-row>
  <!-- 表格 -->
  <div style="background: #fff">
    <el-table
      :data="tableData"
      style="width: 100%"
      class="table_box"
      :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
      v-loading="tableLoading"
      @row-click="setUnpay"
      element-loading-text="Loading..."
    >
      <el-table-column
        prop="number"
        label="Order"
        min-width="250"
        align="center"
      >
        <template #default="{ row }">
          <div class="flex">
            <div style="padding-left: 25px">
              <div style="text-align: left">{{ row.number }}</div>
              <div v-if="row.platform_order_id !== '0' && row.platform_order_id.length > 1" class="platform_code">
                #{{ row.platform_order_id }}({{ row.platform_code }})
              </div>
              <div v-else class="platform_code">None(Manual order)</div>
            </div>
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="row.exception_info_for_customer"
              placement="top"
              v-if="!!row.exception_info_for_customer"
            >
              <i
                class="icon-gantanhao iconfont"
                style="color: red; margin-left: 15px"
              ></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="Date"
        min-width="180"
        align="center"
      />
      <el-table-column
        prop="first_name"
        label="Customer"
        min-width="180"
        align="center"
      />
      <el-table-column
        prop="total_price"
        label="Total"
        min-width="180"
        align="center"
      >
        <template #default="scope"> $ {{ scope.row.total_price }} </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="Status"
        align="center"
        min-width="100"
      >
        <template #default="{ row }">
          <span :style="{ color: row.color }">{{ row.status_word }}</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 暂无数据 -->
    <div class="create_box margin" v-if="dataShow">
      <img src="~@/assets/images/noProduct.png" alt="" />
      <div>You have no orders created.</div>
      <el-button type="primary" class="create_btn" @click="createOrder"
        >Create order</el-button
      >
    </div>

    <!-- 分页 -->
    <div class="pagina">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="meta.total"
        :page-size="meta.per_page"
        @current-change="handleCurrentChange"
        :hide-on-single-page="true"
      ></el-pagination>
    </div>
  </div>

  <div class="back" @click="toTop">
    Back up to top <el-icon class="top_icon"><top /></el-icon>
  </div>

  <!-- 订单详情弹窗 -->
  <OrderDetail
    :dialogOrderDetail="dialogOrderDetail"
    :orderDetailData="orderDetailData"
    :orderItemData="orderItemData"
    :orderItem="orderItem"
    :optionHistorys="optionHistorys"
    @update="update"
    @input="closeDetai"
    :loading="loading"
    @closePopup="closePopup"
    @close="closeDetai"
    @setUpdateDetail="updateDetail"
  />

  <BillingMethod
    v-model="billingMethodShow"
    :sum="sum"
    :orderNumbers="orderNumbers"
    @updateList="updateList"
    @close="billingMethodShow=$event"
  />
</template>

<style lang="scss" scoped>
.tag_box {
  user-select: none;
}
.platform_code {
  font-size: 13px;
  font-weight: 500;
  // color: #eee;
  text-align: left;
}
:deep(.el-table__row) {
  cursor: pointer;
}
.top_icon {
  font-size: 25px;
  margin-left: 10px;
}
.back {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  cursor: pointer;
}
.pagina {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 30px;
}
.box {
  height: 60px;
  line-height: 60px;
  &_del {
    margin-right: 30px;
  }
}
.el-check-tag {
  line-height: 20px;
  font-weight: 400;
  border-radius: 18px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.85);
  background: #e5e5e5;
  opacity: 1;
  &.is-checked {
    color: #fff;
    background: rgba(0, 0, 0, 0.84);
  }
}
.create_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 575px;
  background-color: #fff;
  img {
    margin-left: 20px;
  }
  .create_btn {
    margin-top: 40px;
  }
}
.margin {
  margin-top: 20px;
}
.pagination {
  padding: 30px 0;
  background: #fff;
  text-align: center;
}

@media screen and (max-width: 1300px) {
  .el-check-tag {
    margin-top: 10px;
  }
}
</style>

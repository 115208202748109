<script setup>
import { onUnmounted, reactive, toRefs, ref, onMounted, watch } from "vue";
import searchInput from "../searchInput.vue";
import { Delete, CopyDocument } from "@element-plus/icons";
import { getProductList, getProductStatusCount } from "@/api/myStore.js";
import { ElMessage, ElMessageBox } from "element-plus";
import ProductList from "../product/productList.vue";
import { finishedProduct, copyFinishedProduct } from "@/api/myStore";
import { getFinishedProductMergeStatus } from "@/api/design";
import CopyProducts from "./copyProducts.vue";
import CreateOrder from "../createOrder/createOrder.vue";
let statusCount = ref({});
let isChecked = ref(0);

let showLoading = ref(false);

const props = defineProps({
  storeId: Number | String,
  storeCode: String,
  itemCode: String | Number,
  platform: String | undefined,
});

const emit = defineEmits(["closeLoading"]);

onMounted(async () => {
  keyword.value = sessionStorage.getItem("productName");
  await productStatusCount();
  await productList();
});

//店铺变化
watch(
  () => props.itemCode,
  (newVal, oldVal) => {
    page.value = 1;
    productStatusCount();
    productList();
  }
);

let sortOptions = reactive([
  // 下拉框排序选项
  {
    value: "1",
    label: "Recenfly edited",
  },
  {
    value: "2",
    label: "Recenfly created",
  },
]);
let sort = ref("");
//获取标签数量 number
let productStatusCount = async () => {
  try {
    const { data } = await getProductStatusCount(
      props.itemCode || props.storeCode
    );
    statusCount.value = data;
  } catch (error) {
    ElMessage.error(error);
  }
};

let update = () => {
  productStatusCount();
  productList();
};
//销毁函数
onUnmounted(() => {
  imageMerge.value = [];
  replaceImg();
});

//#region 产品list
let designPage = sessionStorage.getItem("designPage");
let designTop = sessionStorage.getItem("designTop");
let tableData = ref([]); //产品list
let meta = ref({}); //分页数据
let page = ref(1);
let perPage = ref(15);
let imageMerge = ref([]);
//获取产品数据
let productList = async () => {
  showLoading.value = true;
  imageMerge.value = [];
  try {
    const { data } = await getProductList({
      page: designPage || page.value,
      perPage: perPage.value,
      storeCode: props.itemCode || props.storeCode,
      isIssue: isIssue.value === 0 ? isIssue.value : isIssue.value || undefined,
      keyword: keyword.value || undefined,
      createdAtRank: recentlyCreate.value || undefined,
      updatedAtRank: recentlyEdited.value || undefined,
    });

    tableData.value = data?.data?.list.map((val) => {
      val.loading = false;
      val.regenerateShow = false;
      val.page = designPage || page.value;
      // image_merge_status =  0.合成中  1.合成成功  2.合成失败
      //筛选合成中的数据
      if (Number(val.image_merge_status) === 0) {
        imageMerge.value.push(val.id);
      }

      return val;
    });
    meta.value = data?.meta;
    page.value = meta.value.current_page;
    perPage.value = Number(meta.value.per_page);
    await replaceImg();
  } catch (error) {
    console.log(error);
    ElMessage.error(error);
  }
  window.scroll(0, designTop);
  showLoading.value = false;
  emit("closeLoading");
  clearStatus();
};

let clearStatus = () => {
  sessionStorage.removeItem("productName");
  sessionStorage.removeItem("designPage");
  sessionStorage.removeItem("designTop");
  designPage = null;
  designTop = null;
};

let getDesignId = () => {
  let designId = [];

  tableData.value.forEach((e) => {
    designId.push(e.id);
  });

  return designId;
};

defineExpose({
  getDesignId,
});

//替换图片
let replaceImg = async () => {
  //1.有未生成的图片
  if (imageMerge.value.length > 0) {
    //2.循环请求接口传id获取数据
    try {
      const { data } = await getFinishedProductMergeStatus({
        designIds: imageMerge.value,
      });
      //3.筛选出未合成的跟合成的
      imageMerge.value = [];
      data.forEach((e) => {
        tableData.value.forEach((v) => {
          //已合成 && id相等 有图片
          if (
            Number(e.image_merge_status) === 1 &&
            v.id === e.id &&
            e.img.length > 0
          ) {
            v.img = e.img;
            v.image_merge_status = e.image_merge_status;
            // 替换图片
          }
        });

        //未合成
        if (Number(e.image_merge_status) === 0) {
          imageMerge.value.push(e.id);
        }
      });
      setTimeout(() => {
        replaceImg();
      }, 8000);
    } catch (error) {
      console.log(error);
      ElMessage.error(error || "Serve Error");
    }
  } else {
    return;
  }
};

//#endregion

//分页
let handleCurrentChange = (e) => {
  page.value = e;
  productList();
};

//回车事件
let keyword = ref("");
function changeInput(e) {
  keyword.value = e;
  productList();
}

//标签状态
let isIssue = ref(null);
let filterTag = (val) => {
  isIssue.value = val;
  productList();
};

//下拉框筛选
let recentlyEdited = ref("");
let recentlyCreate = ref("");
let selectChange = (e) => {
  if (Number(e) === 1) {
    //最近编辑
    recentlyEdited.value = "desc";
    recentlyCreate.value = "";
  } else if (Number(e) === 2) {
    //最近创建
    recentlyCreate.value = "desc";
    recentlyEdited.value = "";
  } else {
    recentlyEdited.value = "";
    recentlyCreate.value = "";
  }
  productList();
};

//勾选了成品
let productStatus = ref([]);
let selection = (e) => {
  productStatus.value = e.map((val) => val.id);
};

//批量删除
let deleteProduct = async () => {
  ElMessageBox.confirm(
    "Are you sure you want to delete this product?",
    "Warning",
    {
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      type: "warning",
    }
  )
    .then(async () => {
      try {
        const { message } = await finishedProduct({
          designId: productStatus.value,
        });
        ElMessage({
          type: "success",
          message: message,
        });
        update();
      } catch (error) {
        ElMessage.error(error);
      }
    })
    .catch(() => {
      return false;
    });
};

//批量copy
let copyProductShow = ref(false);
let copyProduct = () => {
  copyProductShow.value = true;
};

//回到顶部
let toTop = () => {
  let top = document.documentElement.scrollTop || document.body.scrollTop;
  // 实现滚动效果
  const timeTop = setInterval(() => {
    document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
    if (top <= 0) {
      clearInterval(timeTop);
    }
  }, 10);
};

let setShowLoading = () => {
  showLoading.value = true;
};
let createOrderShow = ref(false);
let createOrder = () => {
  createOrderShow.value = true;
  clearSelectTable.value = false;
};
let clearSelectTable = ref(false);
let closeCreateOrder = () => {
  createOrderShow.value = false;
  productStatus.value = [];
  clearSelectTable.value = true;
};
</script>

<template>
  <el-row class="box">
    <el-col
      v-if="
        !(
          productStatus.length > 0 &&
          Array.isArray(productStatus) &&
          !!productStatus
        )
      "
    >
      <!-- 搜索框 -->
      <search-input @changeInput="changeInput"></search-input>
    </el-col>
    <el-col v-else>
      <!-- 功能按钮 -->

      <el-tooltip content="Delete" placement="top">
        <el-icon size="22" class="box_del" @click="deleteProduct"
          ><Delete
        /></el-icon>
      </el-tooltip>

      <el-tooltip content="Copy to ..." placement="top">
        <el-icon size="22" class="box_del" @click="copyProduct"
          ><CopyDocument
        /></el-icon>
      </el-tooltip>

      <el-tooltip content="Create Order" placement="top">
        <el-icon class="box_del" @click="createOrder">
          <i class="iconfont icon-chuangjiandingdan"></i
        ></el-icon>
      </el-tooltip>
    </el-col>
  </el-row>
  <!-- 标签 状态 -->
  <el-row>
    <el-col :span="20">
      <div class="tag_box">
        <el-check-tag
          :checked="isChecked === 0"
          @click="filterTag(null), (isChecked = 0)"
          >All</el-check-tag
        >
        <el-check-tag
          :checked="isChecked === 1"
          @click="filterTag(0), (isChecked = 1)"
          >Unpublished · {{ statusCount.unpublishedCount }}</el-check-tag
        >
        <el-check-tag
          :checked="isChecked === 2"
          @click="filterTag(1), (isChecked = 2)"
          >Published · {{ statusCount.publishedCount }}</el-check-tag
        >
      </div>
    </el-col>
    <el-col :span="4">
      <el-select
        v-model="sort"
        placeholder="Select"
        clearable
        @change="selectChange"
      >
        <el-option
          v-for="item in sortOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-col>
  </el-row>

  <!-- list -->
  <ProductList
    :tableData="tableData"
    :showLoading="showLoading"
    @selection="selection"
    @update="update"
    :itemCode="itemCode"
    :storeCode="storeCode"
    v-if="tableData.length"
    @setShowLoading="setShowLoading"
    :platform="platform"
    :clearSelectTable="clearSelectTable"
  />

  <!-- 暂无数据 -->
  <div class="create_box margin" v-else>
    <img src="~@/assets/images/noProduct.png" alt="" />
    <div>You have no products created.</div>
    <!-- <el-button type="primary">Create product</el-button> -->
  </div>

  <div class="pagination">
    <el-pagination
      background
      layout="prev, pager, next"
      :total="meta.total"
      :page-size="Number(meta.per_page)"
      :current-page.sync="meta.current_page"
      @current-change="handleCurrentChange"
      :hide-on-single-page="true"
    ></el-pagination>
    <!-- :hide-on-single-page="true" -->
  </div>

  <div class="back" @click="toTop">
    Back up to top <el-icon class="top_icon"><top /></el-icon>
  </div>

  <!-- copy产品 -->
  <CopyProducts
    v-model="copyProductShow"
    @input="copyProductShow = $event"
    :productStatus="productStatus"
    @update="update"
  />

  <!-- 创建订单 -->
  <CreateOrder
    v-model="createOrderShow"
    @input="closeCreateOrder"
    :orderId="productStatus"
  />
</template>

<style lang="scss" scoped>
.tag_box {
  user-select: none;
}
.box_del {
  cursor: pointer;
    height: 30px;
    vertical-align: top;
    margin-top: 15px;
  .icon-chuangjiandingdan {
    margin-bottom: 5px;
    font-size: 20px !important;
    vertical-align: -webkit-baseline-middle;
  }
}
.box {
  height: 60px;
  line-height: 60px;
  &_del {
    margin-right: 30px;
  }
}
.el-check-tag {
  line-height: 20px;
  font-weight: 400;
  border-radius: 18px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.85);
  background: #e5e5e5;
  opacity: 1;
  &.is-checked {
    color: #fff;
    background: rgba(0, 0, 0, 0.84);
  }
}
.create_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 575px;
  background-color: #fff;
  img {
    margin-left: 20px;
  }
}
.margin {
  margin-top: 20px;
}
.pagination {
  padding: 30px 0;
  background: #fff;
  text-align: center;
}
.back {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  cursor: pointer;
}
.top_icon {
  font-size: 25px;
  margin-left: 10px;
}
</style>

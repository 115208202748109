import request from '@/utils/request.js';

/**
 * 获取完成的步骤
 */
export function getCoupon(coupon) {
  return request({
    url: '/coupon/status',
    method: 'get',
    params: {
        coupon
    }
  })
}

import request from "@/utils/request.js"
//订单导入

export function importOrder (file,storeCode) {

  const formData = new FormData()
  formData.append('file', file)
  formData.append('storeCode', storeCode)

  return request({
    url: "/order/import",
    method: "post",
    data: formData
  })
}

//订单导出
export function exportLogisticsUrl ({
  startTime,
  endTime,
  storeCode
}) {
  return request({
    url: "/order/exportLogisticsUrl",
    method: "post",
    data: {
      startTime,
      endTime,
      storeCode
    }
  })
}
<script setup>
import { reactive, defineEmits, defineProps, ref, computed, watch } from "vue";
import png from "@/assets/affiliateProgram/1.png";
import uploadImg from "@/views/store/hooks/uploadImg.js";
// import ImgCutter from "vue-img-cutter";
import ImgCutter from "@/utils/img-cutter";
import { ElMessage } from "element-plus";

const emit = defineEmits([
  "back",
  "setAddProductsShow",
  "determineSelectProduct",
  "changePage",
  "clearShow",
  "closeAddProductsShow",
  "confirm",
]);

const props = defineProps({
  deleteProductId: {
    type: Number,
    default: null,
  },
  openShow: {
    type: Boolean,
    default: false,
  },
  product: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
  clearData: {
    type: Boolean,
    default: false,
  },
  state: {
    type: Boolean,
    default: false,
  },
  meta: {
    type: Object,
    default: () => ({}),
  },
  clearShowFlase: {
    type: Boolean,
    default: false,
  },
});

//已被一级删除的list 可再次选中
watch(
  () => props.deleteProductId,
  () => {
    filterProduct.sizeList.forEach((item) => {
      if (item.id === props.deleteProductId) {
        item.show = false;
      }
    });
  }
);

watch(
  () => props.clearData,
  () => {
    filterProduct.sizeList = [];
    filterProduct.colorList = [];
    colorShow.value = false;
    sizeShow.value = false;
    imgShow.value = false;
  }
);

watch(
  () => props.state,
  () => {
    if (props.state) {
      let item = props.product.list[0];
      getSize(item);
      sizeShow.value = false;
    }
  }
);

watch(
  () => props.clearShowFlase,
  () => {
    if (props.clearShowFlase) {
      filterProduct.sizeList = [];
      filterProduct.colorList = [];
      colorShow.value = false;
      sizeShow.value = false;
      emit("clearShow");
    }
  }
);

let num = computed(() => Number(props.meta.obj.per_page));

let setPrice = computed(() => (val) => {
  return (Number(val) + Number(price.value)).toFixed(2);
});

//回退
let searchProducts = () => {
  emit("back", false);
};

//#region 尺寸
//获取产品尺寸价格
let filterProduct = reactive({
  sizeList: [],
  colorList: [],
});
let price = ref(0.0);
let colorShow = ref(false);
let getSize = (item) => {
  price.value = item.product.price;
  colorShow.value = true;
  filterProduct.colorList = item.design_colors.map((val) => {
    val.productName = item.name;
    val.FactoryEnName = item?.factory_info?.enname;
    val.market_activity = item.product.market_activity;
    return val;
  });

  if (item.design_colors.length <= 1) {
    selectColor(item.design_colors[0]);
  }
};

//回退到产品列表
let backSearchProducts = () => {
  colorShow.value = false;
};

//#endregion

//#region 进入选择size
let sizeShow = ref(false);
let selectColor = (item) => {
  sizeShow.value = true;

  filterProduct.sizeList = item.design_item.map((val) => {
    val.img = item.img;
    val.productName = item.productName;
    val.FactoryEnName = item?.FactoryEnName;
    return val;
  });
};
//回退到选颜色
let backColor = () => {
  sizeShow.value = false;
  if (filterProduct.colorList.length <= 1) {
    colorShow.value = false;
  }
};
//#endregion

let imgShow = ref(false);

let marketActivity = ref({});

let sizeItem = ref({});
//选择产品
let selectSize = (item) => {
  sizeItem.value = item;
  item.show = !item.show;
  if (!!item.show) {
    emit("determineSelectProduct", item);
    //只有design_type等 2  才有上传图片文字
    // || item.type === 3
    if (item.type === 2 || item.type === 3) {
      let values = Object.values(item.design_edit_special.edit);

      let status = values.every((e) => JSON.stringify(e) === "[]");
      if (!status) {
        imgShow.value = true;

        for (const key in item.design_edit_special.edit) {
          if (Array.isArray(item.design_edit_special.edit[key])) {
            item.design_edit_special.edit[key] = item.design_edit_special.edit[
              key
            ].map((e) => {
              let obj = {};
              obj.value = "";
              e.imgUrl = "";
              return { ...e, ...obj };
            });
          }
        }
        item.design_edit_special.edit["Default"] = false;
        item.design_edit_special.type = item.type;

        marketActivity.value = item.design_edit_special;
      } else {
        emit("closeAddProductsShow");
      }
    } else {
      // 不进入上传图片 ， 则进入产品选择好的列表
      emit("closeAddProductsShow");
    }
  } else {
    //删除缓存的产品
    emit("determineSelectProduct", item.id);
    //清除数据
    // for (const key in item.design_edit_special.edit) {
    //   delete item.design_edit_special.edit[key + "Default"];
    // }
  }
};
let productsBox = ref('')
let handleCurrentChange = (e) => {
  productsBox.value.scrollTop  = 0
  emit("changePage", e);
};

let backImg = () => {
  imgShow.value = false;
  //删除已选的size
  selectSize(sizeItem.value);
};

let confirm = () => {
  if (!marketActivity.value.edit.Default) {
    let list = Object.values(marketActivity.value.edit).filter(Boolean);
    let patt = /\p{Unified_Ideograph}/gu;
    list.forEach((e) => {
      let status = e.find((v) => {
        return patt.test(v.value);
      });
      if (status) {
        ElMessage.error(" No Chinese input");
        throw new Error(" No Chinese input");
      }
    });
  }
  emit("closeAddProductsShow");
  emit("confirm", true);
  imgShow.value = false;
};
let { beforeUpload } = uploadImg();

let cutDown = ($event, e) => {
  //false=可选   / true=不可选
  if (!marketActivity.value.edit.Default) {
    const { file, dataURL } = $event;
    e.imgUrl = dataURL;
    beforeUpload(file, e);
  } else {
    console.log("disabled");
  }
};
// 不能输入中文
let noChinese = computed(() => {
  return (e) => {
    let patt = /\p{Unified_Ideograph}/gu;
    return patt.test(e);
  };
});

//禁用
let defaultChange = (e) => {
  marketActivity.value.edit.Default = e;
};
</script>

<template>
  <!-- //一级  选择产品 -->
  <div v-show="!colorShow">
    <div class="searchProducts" @click="searchProducts">
      <i
        class="icon-xiadan-fanhui iconfont"
        style="margin-right: 10px; color: rgba(0, 0, 0, 0.45)"
      >
      </i>
      Add from created products
    </div>
    <div class="products_box" ref="productsBox" v-loading="loading">
      <div
        class="flex img_box"
        v-for="item in product.list"
        :key="item.id"
        @click="getSize(item)"
      >
        <div class="flex">
          <div class="el_img">
            <el-image
              :src="item.img[0].img400"
              style="width: 100%; height: 100%"
            ></el-image>
            <div class="previewImage">
              <el-image
                :src="item.img[0].img400"
                style="width: 100%; height: 100%"
              ></el-image>
            </div>
          </div>
          <div class="img_text">
            <div class="img_title">{{ item.name }}</div>
            <!-- <div>
            {{ item.design_colors.length }} colors ·
            {{ item.item.length }} sizes ·
            {{ !!item.status ? "All in stock" : "" }}
          </div> -->
            <div class="flex">
              <div
                class="font400"
                v-if="!!item.factory_info && !!item.factory_info.enname"
              >
                Factory Code：{{ item?.factory_info?.enname }}
              </div>
              <div
                class="activity"
                v-if="
                  !!item.product.market_activity &&
                  !!item.product.market_activity.status
                "
              >
                Free shipping on orders over ${{
                  item.product.market_activity.satisfy_price
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="rightArrow">
          <i class="icon-youjiantou iconfont"></i>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="meta.obj.total"
          v-model:currentPage="meta.obj.current_page"
          v-model:page-size="num"
          @current-change="handleCurrentChange"
          :hide-on-single-page="true"
          v-if="!loading"
        >
        </el-pagination>
      </div>
    </div>
  </div>

  <!-- 二级 选择颜色 -->

  <div v-show="colorShow && !sizeShow">
    <div class="searchProducts" @click="backSearchProducts(item)">
      <i
        class="icon-xiadan-fanhui iconfont"
        style="margin-right: 10px; color: rgba(0, 0, 0, 0.45)"
      >
      </i>
      Back to created products
    </div>
    <div class="products_box">
      <div
        class="flex img_box"
        v-for="item in filterProduct.colorList"
        :key="item.id"
        @click="selectColor(item)"
      >
        <div class="flex">
          <div class="el_img">
            <el-image
              :src="item.img[0].img400"
              style="width: 100%; height: 100%"
            ></el-image>
            <div class="previewImage">
              <el-image
                :src="item.img[0].img400"
                style="width: 100%; height: 100%"
              ></el-image>
            </div>
          </div>
          <div class="img_text">
            <div class="img_title">{{ item.productName }}</div>
            <div class="flex">
              <div class="font400">
                {{ item.name }} · {{ item.design_item.length }}
                <span v-for="val in item.specification" :key="val.level">
                  <template v-if="Number(val.level) === 2">{{
                    val.name
                  }}</template>
                </span>
              </div>
              <div
                class="activity"
                v-if="!!item.market_activity && !!item.market_activity.status"
              >
                Free shipping on orders over ${{
                  item.market_activity.satisfy_price
                }}
              </div>
            </div>
            <!-- {{item}} -->
            <div class="font400" v-if="!!item.FactoryEnName">
              Factory Code：{{ item?.FactoryEnName }}
            </div>
          </div>
        </div>
        <div class="rightArrow"><i class="icon-youjiantou iconfont"></i></div>
      </div>
    </div>
  </div>

  <!-- 三级 选择size -->
  <div v-show="sizeShow && !imgShow">
    <div class="searchProducts" @click="backColor">
      <i
        class="icon-xiadan-fanhui iconfont"
        style="margin-right: 10px; color: rgba(0, 0, 0, 0.45)"
      >
      </i>
      Back to created products
    </div>
    <div class="products_box">
      <div
        class="flex img_box"
        v-for="item in filterProduct.sizeList"
        :key="item.id"
      >
        <div class="flex">
          <div class="el_img">
            <el-image
              :src="item.img[0].img400"
              style="width: 100%; height: 100%"
            ></el-image>
            <div class="previewImage">
              <el-image
                :src="item.img[0].img400"
                style="width: 100%; height: 100%"
              ></el-image>
            </div>
          </div>
          <div class="img_text">
            <!-- <div class="img_title">{{ item.name }}</div> -->
            <div style="margin-bottom: 5px">
              {{ item.size_name }} · {{ item.color_name }}
            </div>
            <div class="flex">
              <div class="font400">USD {{ setPrice(item.size.price) }}</div>
              <div
                class="activity"
                v-if="!!item.market_activity && !!item.market_activity.status"
              >
                Free shipping on orders over ${{
                  item.market_activity.satisfy_price
                }}
              </div>
            </div>
            <div class="font400" v-if="!!item.FactoryEnName">
              Factory Code：{{ item?.FactoryEnName }}
            </div>
          </div>
        </div>
        <div class="rightArrow">
          <el-button
            type="primary"
            @click="selectSize(item)"
            :class="{ active: item.show }"
            class="btn_select"
            >Select</el-button
          >
        </div>
      </div>
    </div>
  </div>

  <!-- 上传图片上传文字 -->
  <div v-show="imgShow">
    <div class="searchProducts" @click="backImg">
      <i
        class="icon-xiadan-fanhui iconfont"
        style="margin-right: 10px; color: rgba(0, 0, 0, 0.45)"
      >
      </i>
      Back to created products
    </div>
    <div class="products_box">
      <div class="flex">
        <!-- <div class="preview">
          <el-image :src="png"></el-image>
        </div> -->
        <div class="form_box" v-if="!!marketActivity && !!marketActivity.edit">
          <template v-for="(item, key) in marketActivity.edit" :key="key">
            <template v-if="!!item && Array.isArray(item) && item.length > 0">
              <el-form
                label-width="150px"
                style="
                  max-width: 100%;
                  border-bottom: 1px solid;
                  margin-bottom: 10px;
                "
              >
                <div class="page">{{ key }}:</div>
                <template v-for="(val, i) in item" :key="i">
                  <el-form-item
                    :label="val.name + ':'"
                    v-if="val.type === 'text'"
                  >
                    <el-input
                      v-model="val.value"
                      :disabled="marketActivity.edit['Default']"
                      show-word-limit
                      clearable
                      :maxlength="val.textLength"
                    />
                    <div style="color: red" v-if="noChinese(val.value)">
                      No Chinese input
                    </div>
                  </el-form-item>

                  <el-form-item
                    :label="val.name + ':'"
                    v-if="val.type === 'image'"
                  >
                    <ImgCutter
                      label="Upload"
                      :cutHeight="val.imageHeight"
                      :cutWidth="val.imageWidth"
                      @cutDown="cutDown($event, val)"
                      :boxWidth="1000"
                      :boxHeight="600"
                      :sizeChange="false"
                      :previewMode="false"
                      :scaleAble="true"
                      :originalGraph="true"
                    >
                      <!-- <VueCrop
                        ref="vueCropRef"
                        v-show="boxWidth > 0 && boxHeight > 0"
                        :boxWidth="boxWidth"
                        :boxHeight="boxHeight"
                        :showChooseBtn="false"
                        :isModal="false"
                        :previewMode="false"
                        :sizeChange="false"
                        :scaleAble="true"
                        :originalGraph="true"
                        :rate="cropRate"
                        @cutDown="cutDown"
                      /> -->
                      <!-- <template #open>
                        <el-button
                          type="primary"
                          class="btn"
                          :disabled="marketActivity.edit[key + 'Default']"
                          >Upload</el-button
                        >
                      </template> -->
                      <template #cancel>
                        <el-button type="info" style="margin-right: 10px"
                          >Cancel</el-button
                        >
                      </template>
                      <template #confirm>
                        <el-button type="primary" class="btn"
                          >Confirm</el-button
                        >
                      </template>
                    </ImgCutter>
                  </el-form-item>
                  <el-form-item v-if="val.type === 'image'">
                    <div class="flex" style="flex-wrap: wrap">
                      <div class="imgArr" v-if="!!val.imgUrl">
                        <el-image :src="val.imgUrl" style="height: 100%">
                        </el-image>
                      </div>
                    </div>
                  </el-form-item>
                </template>
                <!-- <el-form-item>
                  <el-checkbox
                    label="Use default design"
                    v-model="marketActivity.edit[key + 'Default']"
                  />
                </el-form-item> -->
              </el-form>
            </template>
          </template>

          <div class="confirm_btn">
            <el-checkbox
              label="Use default design"
              style="margin-right: 10px"
              @change="defaultChange"
              v-model="marketActivity.edit.Default"
              v-if="marketActivity.type !== 2"
            />
            <el-button type="primary" @click="confirm">Confirm</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page {
  padding: 20px 0;
}
:deep(.scrollBarText) {
  width: 50px;
}
:deep(.copyright) {
  z-index: -100 !important;
}
:deep(.btn-primary) {
  background-color: #ff2379;
  border: 1px solid transparent;
  color: #fff;
  &:hover {
    background-color: #ff2379;
    border: 1px solid transparent;
    color: #fff;
  }
}
.imgArr {
  width: 122px;
  height: 122px;
  border: 1px solid #00000008;
  margin-right: 10px;
  margin-bottom: 10px;
}
.preview {
  width: 200px;
  height: 200px;
  margin: 20px;
  border: 1px solid #00000008;
}
.form_box {
  flex: 1;
  margin: 20px;
}
.confirm_btn {
  display: flex;
  justify-content: flex-end;
}

.font400 {
  font-weight: 400;
}
.pagination {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.btn_select {
  width: 110px;
  border-radius: 3px;
}
.active {
  background: #0000001a;
  border-color: #0000001a;
}
.flex {
  display: flex;
}
.img_box {
  padding: 0 20px;
  justify-content: space-between;
  height: 102px;
  cursor: pointer;
  &:hover {
    background: #00000008;
  }
  .rightArrow {
    line-height: 100px;
  }
  .el_img {
    width: 70px;
    height: 70px;
    margin-top: 15px;
    // position: relative;
    border: 1px solid #707070;
    &:hover .previewImage {
      display: block;
    }
    .previewImage {
      position: absolute;
      top: 200px;
      left: -320px;
      width: 300px;
      height: 300px;
      background-color: #707070;
      z-index: 2;
      border-radius: 5px;
      overflow: hidden;
      display: none;
    }
  }
  .img_text {
    margin-top: 20px;
    margin-left: 20px;
    .activity {
      margin-left: 20px;
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.14);
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 20px;
      padding: 1px 14px;
      margin-bottom: 4px;
    }
    .img_title {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 8px;
    }
  }
}
.searchProducts {
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);
  line-height: 40px;
  margin-top: 30px;
  padding-left: 30px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  user-select: none;
}
.products_box {
  height: 306px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  overflow: auto;
}

@media screen and (max-width: 1650px) {
  .previewImage {
    left: 125px !important;
    // left: 420px !important;
    // top: 186px !important;
    top: 190px !important;
  }
}
</style>

<template>
  <el-dialog
    :model-value="value"
    title="Copy 1 products to ..."
    width="32%"
    :before-close="handleClose"
  >
    <div style="border: 1px solid #eee"></div>
    <div>
      <el-row v-for="item in storeData.list" :key="item.id" class="el_row">
        <el-col :span="3">
          <el-checkbox-group v-model="checkList.list" class="el_check">
            <el-checkbox :label="item.code" />
          </el-checkbox-group>
        </el-col>
        <el-col :span="15" class="name">{{ item.name }} {{item.is_default === 1 ? '( default )' : ''}}</el-col>
        <el-col :span="6" class="img_box">
          <el-image
            class="img_el"
            :src="item.is_default === 1 && item.is_authorize === 0 ? defaultStore : item.logo"
          ></el-image>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="copyProducts"
          >Copy products</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  defineEmits,
  defineProps,
  computed
} from "vue";
import $store from "@/store/index";
import defaultStore from "@/assets/storeImg/defaultStore.png";
import { ElMessage } from "element-plus";
import { copyFinishedProduct } from "@/api/myStore";

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
storeId:{
    type:Number,
    default:null
},
productStatus:{
    type:Array,
    default:()=>([])
}
});

const emit = defineEmits(["input",'update']);



//关闭弹窗
let handleClose = () => {
  emit("input", false);
  checkList.list = []
};

//店铺list
// 店铺勾选list
let checkList = reactive({ list: [] });
let storeData = reactive({ list: [] });

storeData.list = computed(() => $store.state.store.storeList);

//确定copy
let copyProducts = async () => {

  if (checkList.list.length > 0) {
      // 单个产品多个店铺    props.storeId
      //   多个产品多个店铺  props.productStatus
      let list = []
    try {
      if(!!props.storeId) {
       list = [props.storeId]
      } else {
        list = props.productStatus
      }
      const { message } = await copyFinishedProduct({
        designId: list ,
        storeCode: checkList.list,
      });
        emit('update')
      ElMessage({
        type: "success",
        message: message,
      });
       
    } catch (error) {
      ElMessage.error(error);
    }
     handleClose()
    
  } else {
    ElMessage.error("Please select a store");
  }
};
</script>
<style scoped lang="scss">
.name {
  line-height: 90px;
}
.img_box {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  .img_el {
    width: 60px;
    height: 60px;
    line-height: 90px;
  }
}

.el_check {
  text-align: center;
  line-height: 105px;
}
:deep(.el-checkbox__label) {
  display: none;
}
.el_row {
  height: 90px;
  border-bottom: 1px solid #0000001a;
}
</style>

<script setup>
import { reactive, onMounted, defineEmits, ref, defineProps, watch } from "vue";
import { getWordArea } from "@/api/common.js";
import { ElMessage } from "element-plus";

let formData = reactive({
  fullName: "",
  phone: "",
  country: "",
  countryName: "",
  stateName: "",
  state: "",
  city: "",
  cityId: "",
  zip: "",
  address1: "",
  address2: "",
  addOrderNumber:"",
});

const props = defineProps({
  clearData: {
    type: Boolean,
    default: false,
  },
  statusUS: {
    type: Boolean,
    default: false,
  },
  statusAU: {
    type: Boolean,
    default: false,
  },
});

//清楚数据
watch(
  () => props.clearData,
  () => {
    formData.fullName = "";
    formData.phone = "";
    formData.country = "";
    formData.countryName = "";
    formData.stateName = "";
    formData.state = "";
    formData.city = "";
    formData.cityId = "";
    formData.zip = "";
    formData.address1 = "";
    formData.address2 = "";
    formData.addOrderNumber = ''
    disableSelect.value = false;
    form.value.resetFields();
  }
);

//有澳洲工厂只能选择澳洲国家
let disableSelect = ref(false);
watch(
  () => props.statusAU,
  () => {
    disableSelect.value = props.statusAU;
    if (!!props.statusAU) {
      formData.country = "AU";
      countryChange("AU");
    } else {
      formData.country = "";
    }
  }
);
//有美国工厂只能选择美国国家
watch(
  () => props.statusUS,
  () => {
    disableSelect.value = props.statusUS;
    if (!!props.statusUS) {
      formData.country = "US";
      countryChange("US");
    } else {
      formData.country = "";
    }
  }
);

const emit = defineEmits(["backSetProduct", "determineAddress"]);
onMounted(() => {
  wordArea();
});
//获取地址
let list = reactive({
  country: [],
  states: [],
  cities: [],
});
let wordArea = async () => {
  try {
    const { data } = await getWordArea();
    list.country = data;
  } catch (error) {
    ElMessage.error(error);
  }
};

let rules = reactive({
  fullName: {
    required: true,
    message: "Please enter Full name",
    trigger: "blur",
  },
  phone: {
    required: true,
    message: "Please enter Phone",
    trigger: "blur",
  },
  country: {
    required: true,
    message: "Please select Country",
    trigger: "change",
  },
  state: {
    required: true,
    message: "Please select State / Province",
    trigger: "change",
  },
  city: {
    required: true,
    message: "Please enter City",
    trigger: "change",
  },
  zip: {
    required: true,
    message: "Please enter Postal / Zip Code",
    trigger: "blur",
  },
  address1: {
    required: true,
    message: "Please enter detiled Address",
    trigger: "blur",
  },
  addOrderNumber: {
    max:40,
    message: "Up to 40 characters",
    trigger: "change",
  }
  // address2: {
  //   required: true,
  //   message: "Please select activity resource",
  //   trigger: "blur",
  // },
});

//回退
let backSetProduct = () => {
  emit("backSetProduct");
};

//选中国家时
let countryChange = (code) => {
  formData.state = "";
  formData.city = "";
  formData.cityId = "";
  list.country.forEach((item) => {
    if (item.code === code) {
      formData.countryName = item.name;
      list.states = item.states;
    }
  });
};

//选中省时
let stateChange = (id) => {
  formData.city = "";
  formData.cityId = "";
  list.states.forEach((item) => {
    if (item.id === id) {
      formData.stateName = item.name;
      list.cities = item.cities;
    }
  });
};

//确定地址
const form = ref(null);
let determineAddress = () => {
  form.value.validate((val) => {
    if (val) {
      emit("determineAddress", formData);
    }
  });
};

//城市
const querySearch = (queryString, cb) => {
  const results = queryString
    ? list.cities.filter(createFilter(queryString))
    : list.cities;
  // call callback function to return suggestions
  cb(results);
};
const createFilter = (queryString) => {
  return (restaurant) => {
    return (
      restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
    );
  };
};

//选择城市
let cityChange = (val) => {
  formData.cityId = val.id;
};
</script>

<template>
  <div style="margin-top: 10px">
    <div class="hint" v-if="statusAU">
      Some products can only be shipped to the AUS
    </div>
    <div class="hint" v-if="statusUS">
      Some products can only be shipped to the USA
    </div>
    <el-form
      :model="formData"
      ref="form"
      inline
      label-position="top"
      :rules="rules"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Full name" prop="fullName">
            <el-input
              v-model="formData.fullName"
              placeholder="Please enter Full name"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Phone" prop="phone">
            <el-input
              v-model="formData.phone"
              placeholder="Please enter Phone"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Country" prop="country">
            <el-select
              :disabled="disableSelect"
              v-model="formData.country"
              @change="countryChange"
              filterable
              placeholder="Please select Country"
              clearable
            >
              <el-option
                :label="item.name"
                :value="item.code"
                v-for="item in list.country"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="State / Province" prop="state">
            <el-select
              v-model="formData.state"
              @change="stateChange"
              filterable
              placeholder="Please select State / Province"
              clearable
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in list.states"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--  -->
        <el-col :span="12">
          <el-form-item label="City">
            <el-autocomplete
              v-model="formData.city"
              :fetch-suggestions="querySearch"
              class="inline-input"
              placeholder="Please select City"
              value-key="name"
              @select="cityChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Postal / Zip Code" prop="zip">
            <el-input
              v-model="formData.zip"
              placeholder="Please enter Postal / Zip Code"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Detiled Address" prop="address1">
            <el-input
              v-model="formData.address1"
              placeholder="Please enter detiled Address"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="24">
          <el-form-item label="Address 2 (optional)">
            <el-input
              v-model="formData.address2"
              placeholder="Please enter Address 2"
              clearable
            ></el-input>
          </el-form-item>
        </el-col> -->

        <el-col :span="24">
          <el-form-item label="Original order number" prop="addOrderNumber">
            <el-input
              v-model="formData.addOrderNumber"
              placeholder="Please enter the original order number"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
  <span class="dialog-footer">
    <el-button type="info" @click="backSetProduct">Back</el-button>
    <el-button type="primary" @click="determineAddress">Continue</el-button>
  </span>
</template>

<style scoped lang="scss">
.hint {
  width: 100%;
  height: 60px;
  background: #fef1e0;
  line-height: 60px;
  margin-bottom: 20px;
  border: 1px solid #e56f08;
  color: #e56f08;
  padding-left: 20px;
}
:deep(.el-select),
:deep(.el-autocomplete) {
  width: 100%;
}
:deep(.el-form-item__label) {
  height: 30px;
  line-height: 30px;
}
:deep(.el-form-item) {
  margin-bottom: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>

import { ElMessage } from "element-plus";
import file2base64 from "@/utils/file2base64";
import { useStore } from "vuex";
import { ref } from "vue";
import md5 from "md5";
import { segmentedUploadFile } from "@/utils/awsOss";
import {
  judgeWhetherTheFileHasBeenUploadedByMd5,
  savePicture,
} from "@/api/fileLibrary";

export default function () {
  const beforeUpload = async (file,obj) => {
    handleUpload(file,obj);
    return false;
  };

  const store = useStore();
  const handleUpload = async (file,obj) => {
    const { type, size, name } = file;
    if (type !== "image/jpeg" && type !== "image/png") {
      ElMessage.error("Only PNG or JPEG images are supported");
      return;
    }
    if (size > 20 * 1024 * 1024) {
      ElMessage.error("Up to 20M files are supported");
      return;
    }
    const base64 = await file2base64(file);
    const md5String = md5(base64);
    // 判断是否上传过此图片没有
    let res;
    try {
      const resData = await judgeWhetherTheFileHasBeenUploadedByMd5(md5String);
      res = resData.data;
    } catch (e) {
      ElMessage.error(e);
      return;
    }

    if (res.verify.result) {
      //未上传过
      try {
        const resData = await segmentedUploadFile(
          file,
          store.state.user.userInfo.userCode,
          md5String
        );
        const { Key } = resData;
        const { data } = await savePicture({
          md5: md5String,
          larger: Key,
          name,
        });
        obj.value= data?.larger?.img
        
      } catch (e) {
        ElMessage.error(e);
      }
    } else {
      //上传过
      const { larger, img } = res.verify.img_url;
      try {
        const { data } = await savePicture({
          md5: md5String,
          larger,
          name: res.verify.name,
        });
        obj.value= data?.larger?.img
      } catch (e) {
        ElMessage.error(e);
      }
    }
  };

  return {
    beforeUpload,
  };
}

<script setup>
import {
  defineProps,
  ref,
  defineEmits,
  onMounted,
  reactive,
  onBeforeMount,
} from 'vue'
import { loadStripe } from '@stripe/stripe-js'
import { ElMessage } from 'element-plus'
import {
  payByPaypal,
  payByBalance,
  payByStripe,
  payByAirwallex,
} from '@/api/common.js'
import { payChannelList } from '@/api/base'
import {
  createElement,
  loadAirwallex,
  getElement,
  confirmPaymentIntent,
  redirectToCheckout,
} from 'airwallex-payment-elements'
import balance from '@/assets/paypay/balance.png'
import paypay from '@/assets/paypay/paypay.png'
import safety from '@/assets/paypay/safety.png'
import Airwallex from './svg/airwallex.vue'
const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  sum: {
    type: String,
  },
  orderNumbers: {
    type: String,
  },
})

const emit = defineEmits(['updateList', 'close'])

let loading = ref(false)

onMounted(() => {})

let stripeStatus = ref(false)
let paypalStatus = ref(false)
let balanceStatus = ref(false)
let airwallexStatus = ref(false)
let payStatus = async () => {
  loading.value = true
  try {
    const { data } = await payChannelList();

    data.forEach((e) => {
      if (e.name === 'stripe') {
        stripeStatus.value = e.isOpen
      } else if (e.name === 'paypal') {
        paypalStatus.value = e.isOpen
      } else if (e.name === 'balance') {
        balanceStatus.value = e.isOpen
      } else if(e.name === 'airwallex') {
        airwallexStatus.value = e.isOpen
      }
    })

    if (!!stripeStatus.value) {
      await getPayByStripe()
      await initStripe()
    }

    setTimeout(() => {
        loading.value = false
    }, 500)
  } catch (error) {
    ElMessage.error(error || 'Serve Error')
    loading.value = false
  }
}

let open = async () => {
  payStatus()
  initAirwallex()
  window.addEventListener('onBlur', onBlur)
}


// 清空数据
let handleClose = () => {
  emit('close', false)
  show.value = false
  paypayShow.value = false
  cardShow.value = false
  cardLoading.value = false
  stripeStatus.value = false
  paypalStatus.value = false
  balanceStatus.value = false
  airwallexShow.value = false
  airwallexLoading.value = false
  airwallexError.value = null
  window.removeEventListener('onBlur', onBlur)
}

let show = ref(false)

let paypayShow = ref(false)

let proceedToPaymentLoading = ref(false)
let payment = async () => {
  proceedToPaymentLoading.value = true
  try {
    const { data } = await payByPaypal({
      orderNumbers: props.orderNumbers,
    })
    window.location.href = data.approveUrl
  } catch (error) {
    ElMessage.error(error)
  }
  proceedToPaymentLoading.value = false
}

let paypayHide = () => {
  show.value = false
  airwallexShow.value = false
  paypayShow.value = true
  cardShow.value = true
}

let cardHide = () => {
  show.value = false
  airwallexShow.value = false
  paypayShow.value = false
  cardShow.value = false
}

let balanceShow = () => {
  show.value = true
  paypayShow.value = false
  airwallexShow.value = false
  cardShow.value = true
}

let setAirwallexShow = () => {
  show.value = false
  paypayShow.value = false
  airwallexShow.value = true
  cardShow.value = true
}

//余额支付

let balancePayLoading = ref(false)

let balancePay = async () => {
  balancePayLoading.value = true
  try {
    const { message } = await payByBalance({
      orderNumbers: props.orderNumbers,
    })
    ElMessage.success(message)
    emit('updateList')
    //谷歌广告埋点
    gtag('event', 'conversion', {
      send_to: 'AW-10926694714/Gw4fCJOP7cUDELrCoNoo',
      transaction_id: props.orderNumbers,
    })

    //广告埋点 Reddit Pixel
    rdt('track', 'Purchase')
  } catch (error) {
    ElMessage.error(error)
  }
  balancePayLoading.value = false
}

//信用卡

let stripe = ref('')
let cardElement = ''
let cardError = ref('')
let cardShow = ref(false)
let elements = ''
let initStripe = async () => {
  stripe.value = await loadStripe(process.env.VUE_APP_CODE_STRIPE) //dev

  const options = {
    clientSecret: clientSecret.value,
    locale: 'US',
  }

  elements = stripe.value.elements(options)
  cardElement = elements.create('payment')
  cardElement.mount('#card-element')
}

// 获取密钥
let clientSecret = ref('')
let getPayByStripe = async () => {
  try {
    const { data } = await payByStripe({
      orderNumbers: props.orderNumbers,
    })
    clientSecret.value = data?.clientSecret
  } catch (error) {
    loading.value = false
    stripeStatus.value = false
    ElMessage.error(error || 'Serve Error')
  }
}

//信用卡支付
let url = process.env.VUE_APP_CONSOL
let cardLoading = ref(false)
let storeCode = sessionStorage.getItem('setStoreCode')
let payCard = async () => {
  cardLoading.value = true
  stripe.value
    .confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      // redirect: "if_required",   //禁止跳转
      confirmParams: {
        return_url: `${url}/store?storeCode=${storeCode}&order=1&orderNumbers=${props.orderNumbers}&num=${props.sum}`,
      },
    })
    .then((data) => {
      if (data?.error) {
        cardError.value = data.error.message
      } else {
        //成功的信息
        console.log(data?.paymentIntent)
      }

      cardLoading.value = false
    })
    .catch((error) => {
      cardError.value = error.error.message
      cardLoading.value = false
    })
}

//云支付
let airwallexShow = ref(false)
const initAirwallex = () => {
  loadAirwallex({
    env: 'prod', //'staging | 'demo' | 'prod'
    origin: window.location.origin,
    locale: 'en',
  })
    .then(() => {
      const cardNumber = createElement('cardNumber')
      const cvcEle = createElement('cvc')
      const expiryEle = createElement('expiry')
      cardNumber.mount('cardNumber')
      cvcEle.mount('cvc') // Same as above
      expiryEle.mount('expiry') // Same as above
    })
    .catch((e) => {
      ElMessage.error(e?.message || e)
    })
}
let airwallexError = ref(null)
let airwallexLoading = ref(false)
let airwallexPay = () => {
  airwallexLoading.value = true
  getPayByAirwallex()
}

//获取密钥
let getPayByAirwallex = async () => {
  try {
    const { data } = await payByAirwallex({
      orderNumbers: props.orderNumbers,
    })
    const { id, client_secret } = data
    setConfirmPaymentIntent(id, client_secret)
  } catch (error) {
    airwallexLoading.value = false
    ElMessage.error(error?.message || error)
  }
}
// 支付
let setConfirmPaymentIntent = (id, client_secret) => {

  const cardNumElement = getElement('cardNumber')
  emit('close', false)

  confirmPaymentIntent({
    element: cardNumElement,
    id,
    client_secret,
    payment_method_options: {
      card: true,
    },
  })
    .then((response) => {
      console.log(response)
      ElMessage.success('Payment successful')
      emit('updateList')
    })
    .catch((error) => {
      ElMessage.error(error?.message || error)
      airwallexError.value = error.message
    })
  airwallexLoading.value = false
}

const onBlur = (event) => {
  const { error, type } = event.detail
  const element = document.getElementById(`${type}-error`)
  if (element && error) {
    element.innerHTML = error.message || JSON.stringify(error) // Example: set input error message
  } else {
    element.innerHTML = ''
  }
}
</script>

<template>
  <el-dialog
    :model-value="value"
    title="Billing method"
    :close-on-click-modal="false"
    width="960px"
    :before-close="handleClose"
    :destroy-on-close="false"
    @open="open"
    :append-to-body="true"
    top="8vh"
  >
    <div class="between" v-loading="loading">
      <!-- 左侧 -->
      <div>
        <!-- 信用卡 -->
        <div
          class="payment"
          @click="cardHide"
          v-if="stripeStatus"
          @ready="onReady"
        >
          <div>
            <i class="icon-yinhangka iconfont"></i> Credit or Debit card
          </div>
          <div v-show="!cardShow">
            <div id="card-element"></div>
            <div id="card-errors">{{ cardError }}</div>
            <el-button
              type="primary"
              :loading="cardLoading"
              style="width: 100%"
              @click.stop="payCard"
              >Fulfill order for USD {{ sum }}</el-button
            >
          </div>
        </div>

        <!-- 云支付 -->
        <div class="airwallex" id="airwallex" @click="setAirwallexShow" v-if="airwallexStatus">
          <div>
            <Airwallex />
          </div>
          <div class="field-container" v-show="airwallexShow">
            <div>
              <div class="field-label">Card number</div>
              <div id="cardNumber" />
              <p id="cardNumber-error" :style="{ color: 'red' }" />
            </div>
            <div>
              <div class="field-label">EXPIRY</div>
              <div id="expiry" />
              <p id="expiry-error" :style="{ color: 'red' }" />
            </div>
            <div>
              <div class="field-label">CVC</div>
              <div id="cvc" />
              <p id="cvc-error" :style="{ color: 'red' }" />
            </div>
            <el-button
              class="paypay_btn"
              type="primary"
              @click.stop="airwallexPay"
              :loading="airwallexLoading"
            >
              Fulfill order for USD {{ sum }}
            </el-button>
            <div class="airwallexError">
              {{ airwallexError }}
            </div>
          </div>
          <div id="page-wrapper"></div>
        </div>

        <!-- paypay -->
        <div class="paypay" @click="paypayHide" v-if="paypalStatus">
          <div class="paypay_img">
            <el-image :src="paypay"></el-image>
          </div>
          <div v-show="paypayShow">
            <div class="paypay_text">
              Press the yellow PayPal button below to authorize your account.
            </div>
            <el-button
              class="paypay_btn"
              type="primary"
              @click.stop="payment"
              :loading="proceedToPaymentLoading"
              >Fulfill order for USD {{ sum }}</el-button
            >
          </div>
        </div>

        <!-- 余额 -->
        <div class="balance" @click="balanceShow" v-if="balanceStatus">
          <div class="balance_img">
            <el-image :src="balance"></el-image>
            <span
              style="
                line-height: 20px;
                margin-left: 10px;
                vertical-align: super;
              "
              >Balance</span
            >
          </div>
          <div v-show="show">
            <div class="paypay_text">Current balance</div>
            <el-button
              class="paypay_btn"
              type="primary"
              @click.stop="balancePay"
              :loading="balancePayLoading"
            >
              Fulfill order for USD {{ sum }}
            </el-button>
          </div>
        </div>
      </div>

      <!-- 右侧 -->
      <div class="describe">
        <div class="between">
          <div class="describe_icon">
            <el-image :src="safety"></el-image>
          </div>
          <div class="describe_text">
            Your data is safe—we don't store your card or billing method
            details, and we use industry-standard encryption to protect your
            personal information.
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped lang="scss">
.airwallexError {
  margin-top: 20px;
  color: red;
  text-align: center;
}
.field-container {
  margin-top: 30px;
}
#cardNumber,
#expiry,
#cvc {
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  margin-top: 10px;
}

.icon-yinhangka {
  font-size: 25px;
  vertical-align: text-bottom;
  margin-right: 10px;
}
#card-element {
  padding: 10px;
  width: 400px;
  border-style: none;
  // background-color: #eee;
  box-shadow: 0 6px 9px rgb(50 50 93 / 6%), 0 2px 5px rgb(0 0 0 / 8%),
    inset 0 1px 0 #eee;
  border-radius: 4px;
  margin-top: 30px;
}
#card-errors {
  color: red;
  margin-bottom: 20px;
  text-align: left;
  width: 400px;
  margin-top: 5px;
}
.between {
  display: flex;
  justify-content: space-between;
  min-height: 300px;
}
.paypay,
.airwallex,
.balance {
  margin-top: 30px;
}

.paypay,
.balance,
.airwallex,
.payment {
  width: 445px;
  background: rgba(255, 255, 255, 0.39);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 20px;
  cursor: pointer;

  &_img {
    width: 90px;
    height: 30px;
    // background-color: blue;
  }
  &_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 20px;
  }
  &_btn {
    width: 100%;
    margin-top: 10px;
  }
}
.describe {
  width: 445px;
  height: 124px;

  background: rgba(70, 185, 149, 0.02);
  border: 1px solid #1eab7f;
  opacity: 1;
  border-radius: 3px;
  padding: 20px 20px 20px 30px;
  &_icon {
    width: 32px;
    height: 40px;
    // background: rgba(30, 171, 127, 0.39);
    margin-right: 30px;
    margin-top: 10px;
  }
  &_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #1eab7f;
    flex: 1;
  }
}

:deep(iframe) {
  width: 400px !important;
}
iframe {
  width: 400px !important;
}
</style>

<script setup>
import { defineProps, defineEmits, onMounted, ref, computed, watch } from "vue";
import { availableLogistics, create } from "@/api/myStore.js";
import { ElMessage, ElMessageBox } from "element-plus";
import { payByPaypal } from "@/api/common.js";
import { getCoupon } from "@/api/coupon";
const props = defineProps({
  productArr: {
    type: Object,
    default: () => ({}),
  },
  addressInfo: {
    type: Object,
    default: () => ({}),
  },
  integrateDeduction: {
    type: Number,
    default: () => 0.0,
  },
  expendIntegrate: {
    type: Number,
    default: () => 0,
  },
  calculateWeight: {
    type: Number,
    default: () => 0,
  },
});

let points = ref("1");

const emit = defineEmits([
  "back",
  "close",
  "closePopup",
  "deleteProductId",
  "updateIntegral",
]);
//回退到确定地址
let back = () => {
  emit("back");
};

onMounted(() => {
  if (props.productArr.length > 0) {
    setAvailableLogistics();
  }
});

//小小计
let setTotalPrice = computed(() => (item) => {
  let money = (
    (Number(item.price) + Number(item.size.price)) *
    Number(item.num)
  ).toFixed(2);
  return money;
});

//总金额
let totalMoney = computed(() => {
  //是否勾选积分
  if (!!coinsShow.value) {
    //物品价格减去积分减去优惠卷小于0.99则以0.99计算
    if (
      (
        Number(subTotal.value) -
        Number(props.integrateDeduction) -
        Number(couponMoney.value)
      ).toFixed(2) < 0.99
    ) {
      return (0.99 + Number(logisticsPrice.value)).toFixed(2);
    } else {
      //大于0.99
      return (
        Number(subTotal.value) +
        Number(logisticsPrice.value) -
        Number(props.integrateDeduction) -
        Number(couponMoney.value)
      ).toFixed(2);
    }
  } else {
    return (
      Number(subTotal.value) +
      Number(logisticsPrice.value) -
      Number(couponMoney.value)
    ).toFixed(2);
  }
});

//获取物流
let subTotal = ref(0); //小计
let loading = ref(false);
let logisticsList = ref([]);
let setAvailableLogistics = async () => {
  loading.value = true;
  // let weight = ref(0);
  let factory_code = ref([]);
  let siteCode = ref("");
  props.productArr.forEach((item) => {
    // weight.value = Number(item.size.weight) * item.num + weight.value;
    factory_code.value = item.factory_code;
    siteCode.value = item.site_code;
    let money = (
      (Number(item.price) + Number(item.size.price)) *
      Number(item.num)
    ).toFixed(2);
    subTotal.value += Number(money);

    // factory_code.value.push(item.factory_code)
  });

  // let arr =[...new Set(factory_code.value)].join()

  try {
    const { data } = await availableLogistics({
      factoryCode: factory_code.value,
      weight: props.calculateWeight,
      siteCode: siteCode.value,
      destinationCode: props.addressInfo.obj.country,
      regionType: props.addressInfo.obj.cityId ? 3 : 2,
      regionId: props.addressInfo.obj.cityId || props.addressInfo.obj.state,
    });
    logisticsList.value = data.map((val, i) => {
      val.show = false;
      if (!i) {
        val.show = true;
        logisticsPrice.value = val.price;
        transportCode.value = val.transport_code;
      }
      return val;
    });
  } catch (error) {
    ElMessage.error(error);
  }
  loading.value = false;
};

// 选择物流
let logisticsPrice = ref(0.0);
let transportCode = ref("");
let setShipping = (item) => {
  logisticsList.value.forEach((item) => (item.show = false));
  logisticsPrice.value = item.price;
  item.show = true;
  transportCode.value = item.transport_code;
};

// 创建订单 接口
let orderNumbers = ref(null); //订单号
let totoalPrice = ref("");
let createOrder = async (e) => {
  let list = [];
  let materials = {};
  let options = {};
  //拼数据
  props.productArr.forEach((item) => {
    materials = {};
    //使用默认的不穿
    if (item?.design_edit_special?.edit) {
      let status = item.design_edit_special.edit.Default;
      for (const key in item.design_edit_special.edit) {
        let val = item.design_edit_special.edit[key];
        if (!status) {
          if (!!val && Array.isArray(val) && val.length > 0) {
            materials[key] = [];
            val.forEach((v) => {
              if (!!v.value && v.value.length > 0) {
                options = {};
                options.name = v.name;
                options.value = v.value;
                options.type = v.type;
                !!materials[key] && materials[key].push(options);
              }
            });
          }
        }
      }
    }
    if (JSON.stringify(materials) !== "{}") {
      materials.source = "local"; //固定写死 php 做处理使用
    }

    let obj = {};
    obj.amount = item.num;
    obj.id = item.id;
    obj.materials = materials;
    list.push(obj);
  });
  const {
    fullName,
    address1,
    address2,
    city,
    phone,
    country,
    state,
    zip,
    stateName,
    countryName,
    addOrderNumber,
  } = props.addressInfo.obj;

  try {
    const { message, data } = await create({
      designItems: list,
      fullName,
      phone,
      country: country,
      stateOrProvince: stateName,
      city: city || stateName,
      postal: zip,
      address1,
      address2,
      transportCode: transportCode.value,
      coupon: couponCode.value || undefined,
      platformOrderId: addOrderNumber || undefined,
    });
    if (Array.isArray(data?.orders) && data?.orders.length > 0) {
      orderNumbers.value = data?.orders.join(",");
      totoalPrice.value = data?.totoalPrice;
    }
    if (!e) {
      ElMessage({
        type: "success",
        message,
      });
    }
  } catch (error) {
    ElMessage.error(error);
    return;
  }
};

let saveForLaterLoading = ref(false);
let saveForLater = async () => {
  saveForLaterLoading.value = true;
  await createOrder();

  if (!!orderNumbers.value && totoalPrice.value) {
    emit("close", true);
  }
  saveForLaterLoading.value = false;
};

//继续支付
let proceedToPaymentLoading = ref(false);
let proceedToPayment = async () => {
  await createOrder(true);
  if (!!orderNumbers.value && totoalPrice.value) {
    emit("closePopup", totoalPrice.value, orderNumbers.value);
  }
};

let coinsShow = ref(true);
let changePoints = (e) => {
  coinsShow.value = e;
};

//优惠卷
let couponCode = ref("");
//优惠金额
let couponMoney = ref("0.00");
//获取优惠卷code码
let applyCouponCode = async () => {
  // 不同工厂不允许用优惠卷
  let status = props.productArr.every(
    (item) => item.factory_code === props.productArr[0].factory_code
  );
  if (!status) {
    ElMessage.error(
      "Coupon redemption failed, products from different factories were detected in the order."
    );
    return false;
  }

  //参与活动的产品不能使用优惠卷
  let someStatus = props.productArr.some(
    (e) => !!e?.market_activity && !!e?.market_activity?.status
  );

  if (!!someStatus) {
    ElMessage.error(
      "The order can't use this coupon as it has another sales activity."
    );
    return false;
  }

  // subTotal.value 小计
  // props.integrateDeduction 积分抵扣
  try {
    const data = await getCoupon(couponCode.value);
    let type = Number(data?.data?.type);
    let detail = Number(data?.data?.detail);
    let overMoney = Number(data?.data?.overMoney);
    let productMoney = (
      Number(subTotal.value) - Number(props.integrateDeduction)
    ).toFixed(2); //产品金额 - 积分金额
    if (type === 1) {
      // 产品价格*100 / 100
      if (productMoney >= overMoney) {
        couponMoney.value = ((productMoney * detail) / 100).toFixed(2);
      } else {
        ElMessage.warning("The code not eligible for use");
        couponMoney.value = "0.00";
      }
    } else if (type === 2) {
      // 产品金额大于等于overMoney直接减这个金额

      if (productMoney >= overMoney) {
        couponMoney.value = detail;
      } else {
        ElMessage.warning("The code not eligible for use");
        couponMoney.value = "0.00";
      }
    } else if (type === 0) {
      //过期 异常
      ElMessage.error(data?.message);
      couponMoney.value = "0.00";
    }
  } catch (error) {
    ElMessage.error(error);
    couponMoney.value = "0.00";
  }
};

//未填写优惠码不能点击按钮
let applyBtn = ref(false);
watch(couponCode, () => {
  if (couponCode.value.length > 0) {
    applyBtn.value = true;
  } else {
    applyBtn.value = false;
  }
});

// 不同工厂不能使用优惠卷
// let status = computed(() => {
//   return
// });

//删除产品
let deleteProduct = async (e) => {
  if (props.productArr.length > 1) {
    ElMessageBox.confirm(
      "proxy will permanently delete the file. Continue?",
      "Delete",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(async () => {
        //删除产品
        await emit("deleteProductId", e.id);
        //重新获取积分
        await emit("updateIntegral");
        //重新获取价格
        subTotal.value = 0;
        await setAvailableLogistics();
      })
      .catch(() => {
        return false;
      });
  }
};
</script>

<template>
  <div class="info_box">
    <div class="left_box">
      <el-row class="title_box">
        <el-col :span="15" style="padding-left: 20px">Product</el-col>
        <el-col :span="3" style="text-align: center">Qty</el-col>
        <el-col :span="3">Cost</el-col>
        <el-col :span="3">Total</el-col>
        <!-- <el-col :span="2">操作</el-col> -->
      </el-row>
      <div class="product_box">
        <el-row
          class="procuct_info_box"
          v-for="item in props.productArr"
          :key="item.id"
        >
          <el-col :span="15" style="padding-left: 20px" class="product_info">
            <div class="img_box">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.img[0].img400"
              ></el-image>
            </div>
            <div>
              <div class="product_text">{{ item.productName }}</div>
              <div class="flex">
                <div class="product_size">
                  {{ item.color_name }} / {{ item.size_name }}
                </div>
              </div>

              <div style="font-weight: 400" v-if="!!item.FactoryEnName">
                Factory Code：{{ item?.FactoryEnName }}
              </div>
              <div
                class="activity"
                v-if="!!item.market_activity && !!item.market_activity.status"
              >
                Free shipping on orders over ${{
                  item.market_activity.satisfy_price
                }}
              </div>
            </div>
          </el-col>
          <el-col :span="3">
            <div style="text-align: center">{{ item.num }}</div>
          </el-col>
          <el-col :span="3">
            <div>
              ${{ (Number(item.price) + Number(item.size.price)).toFixed(2) }}
            </div>
          </el-col>
          <el-col :span="3">
            <div>${{ setTotalPrice(item) }}</div>
          </el-col>
          <!-- <el-col :span="2" class="delete" @click="deleteProduct(item)"
            >Delete</el-col
          > -->
        </el-row>
      </div>
      <!-- <div class="status" v-if="!status">
        Products from different factories cannot be submitted together
      </div> -->
      <div>
        <el-radio v-model="points" label="1" size="large"
          >Use {{ expendIntegrate }} Coins to get ${{
            integrateDeduction
          }}
          off.</el-radio
        >
        <!-- @change="changePoints" -->
      </div>
      <!-- //物流 -->
      <div class="shipping_method">
        <div class="shipping_method_text">Shipping method</div>
        <div v-loading="loading" class="div_box">
          <div
            class="shipping_box"
            v-for="(item, index) in logisticsList"
            :key="index"
            @click="setShipping(item)"
            :class="{ active: item.show }"
          >
            <template
              v-if="
                logisticsList.length > 0 &&
                Array.isArray(logisticsList) &&
                !!logisticsList
              "
            >
              <div class="shipping_box_left">
                <div>{{ item.transport_name_en }}</div>
                <div class="introduce_text">
                  {{ item.transport_description }}
                </div>
              </div>
              <div class="shipping_box_right">${{ item.price }}</div>
            </template>
            <template v-else>
              <div>No shipping method. This product ships to the USA only.</div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="right_box">
      <div class="right_acitve">
        <div class="Address_text">Shipping Address</div>
        <div class="Address_info_text">{{ addressInfo.obj.fullName }}</div>
        <div class="Address_info_text">{{ addressInfo.obj.address1 }}</div>
        <div class="Address_info_text">{{ addressInfo.obj.address2 }}</div>
        <div class="Address_info_text">
          {{ addressInfo.obj.stateName }} / {{ addressInfo.obj.zip }}
        </div>
        <div class="Address_info_text">{{ addressInfo.obj.city }}</div>
        <!-- <div class="Address_info_text">{{}}</div> -->
        <div class="Address_info_text">{{ addressInfo.obj.countryName }}</div>
        <div class="Address_info_text">{{ addressInfo.obj.phone }}</div>
      </div>
      <div class="price">
        <div>Subtotal</div>
        <div>${{ subTotal.toFixed(2) }}</div>
      </div>
      <div class="price">
        <div>Shipping</div>
        <div>${{ logisticsPrice }}</div>
      </div>
      <div class="price" v-show="coinsShow">
        <div>Coins</div>
        <div>-${{ integrateDeduction.toFixed(2) }}</div>
      </div>

      <div class="price" v-show="coinsShow">
        <div>Coupon</div>
        <div>-${{ couponMoney }}</div>
      </div>
      <div class="price" style="margin-bottom: 10px">
        <div>Total</div>
        <div>${{ totalMoney }}</div>
      </div>

      <el-input
        v-model="couponCode"
        style="margin-bottom: 10px"
        placeholder="Coupon code"
        class="input-with-select"
      >
        <!-- :disabled="!status" -->

        <template #append>
          <el-button
            class="apply_btn"
            type="primary"
            @click="applyCouponCode"
            :disabled="!applyBtn"
            :class="{ opacity: !applyBtn }"
          >
            Apply
          </el-button>
        </template>
      </el-input>
      <el-button
        class="proceed_to_payment"
        type="primary"
        @click="proceedToPayment"
        :loading="proceedToPaymentLoading"
        :disabled="
          !(
            logisticsList.length > 0 &&
            Array.isArray(logisticsList) &&
            !!logisticsList
          )
        "
        >Proceed to payment</el-button
      >
      <el-button
        class="proceed_to_payment"
        @click="saveForLater"
        :loading="saveForLaterLoading"
        :disabled="
          !(
            logisticsList.length > 0 &&
            Array.isArray(logisticsList) &&
            !!logisticsList
          )
        "
        >Save for later</el-button
      >
      <el-button type="info" class="proceed_to_payment" @click="back"
        >Back</el-button
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.activity {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 20px;
  padding: 1px 14px;
  margin-bottom: 4px;
  margin-top: 5px;
}
.delete {
  cursor: pointer;
  &:hover {
    color: red;
  }
}
.status {
  color: red;
  margin-top: 10px;
}
.opacity {
  opacity: 0.5;
}
.apply_btn {
  width: 56px;
  height: 40px;
  border-radius: 0px 3px 3px 0px;
  font-size: 14px;
  font-weight: 400;
  padding-left: 12px;
  background-color: #ff2379 !important;
  color: #fff !important;
}
.active {
  background: rgba(70, 185, 149, 0.02) !important;
  border: 1px solid #1eab7f !important;
}
.info_box {
  display: flex;
  margin-top: 30px;
  .left_box {
    flex: 1;
    margin-right: 20px;
    .title_box {
      line-height: 40px;
      border-bottom: 1px solid #0000001a;
      padding-right: 10px;
    }
    .product_box {
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
      max-height: 300px;
      overflow: auto;
      padding-right: 10px;
      .procuct_info_box {
        margin-top: 20px;
        .product_info {
          display: flex;
          .img_box {
            width: 50px;
            height: 50px;
            margin-right: 20px;
          }
        }
        .product_text {
          font-size: 16px;
          line-height: 22px;
          color: #000000d9;
        }
        .product_size {
          color: #00000073;
        }
      }
    }
    .shipping_method {
      margin-top: 20px;
      .shipping_method_text {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #000000d9;
        opacity: 1;
        margin-bottom: 20px;
      }
      .div_box {
        max-height: 400px;
        overflow: auto;
      }
      .shipping_box {
        display: flex;
        justify-content: space-between;
        height: 150px;
        background: rgba(255, 255, 255, 0.39);
        border: 1px solid rgba(112, 112, 112, 0.2);
        opacity: 1;
        border-radius: 3px;
        padding: 15px 20px;
        margin-top: 10px;
        cursor: pointer;
        .shipping_box_left {
          flex: 1;
          .introduce_text {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #000000a6;
            margin-top: 10px;
          }
        }
        .shipping_box_right {
          display: flex;
          justify-content: flex-end;
          width: 100px;
        }
      }
    }
  }
  .right_box {
    width: 200px;
    // height: 548px;
    background: #00000008;
    opacity: 1;
    border-radius: 3px;
    padding: 10px;
    .right_acitve {
      border-bottom: 1px solid #0000001a;
      margin-bottom: 5px;
      .Address_text {
        color: #000000d9;
        margin-bottom: 5px;
      }
      .Address_info_text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #000000d9;
        padding: 5px 0;
      }
    }
    .price {
      display: flex;
      justify-content: space-between;
      padding: 5px;
    }
    .proceed_to_payment {
      width: 100%;
      height: 36px;
      opacity: 1;
      border-radius: 3px;
      margin-left: 0 !important;
      margin-bottom: 10px;
    }
  }
}
</style>

<template>
  <el-row>
    <el-input
      :placeholder="placeholder"
      :prefix-icon="Search"
      v-model="searchValue"
      clearable 
      @change="inputChange(e)"
    />
  </el-row>
</template>


<script setup>
import { Search } from '@element-plus/icons'
import {defineEmits, ref, defineProps} from 'vue';
let searchValue = ref("");

let productName =  sessionStorage.getItem("productName");

  if(productName) {
    searchValue.value = productName
  }

const props = defineProps({
  placeholder:{
    type:String,
    default:'Search'
  }
})
const emit = defineEmits(['changeInput'])
let inputChange = () => {
  emit('changeInput',searchValue.value)
}
</script>

<style lang="scss" scoped>

</style>
<script setup>
import { reactive, onMounted, ref, defineProps, defineEmits, watch } from 'vue'
import { useRoute } from 'vue-router'
import ProductsTop from '../product/productsTop.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import OrderList from '../orderList/orderList.vue'
import CreateOrder from '../createOrder/createOrder.vue'
import BillingMethod from '@/components/billingMethod.vue'
import { syncCreditCard } from '@/api/common.js'
import { getDesignerProductExportUrl } from '@/api/design'
import { setDefaultStore } from '@/api/myStore'
import { importOrder, exportLogisticsUrl } from '@/api/order'
import Store from 'store'

let activeName = ref('product')

let loading = ref(true)
let route = useRoute()
onMounted(() => {
  getStore()
  updateCreditCard()
  setMessage()
})

let orderNumberShow = sessionStorage.getItem('messageOrderNumber')

let show = ref(false)
show.value = sessionStorage.getItem('messageOrderShow')

let setMessage = () => {
  const { order, orderNumbers, num, payment_intent } = route.query

  if (Number(order) === 1) {
    activeName.value = 'order'
  }

  //有无订单号
  if (!orderNumberShow) {
    //没有订单号存一个
    sessionStorage.setItem('messageOrderNumber', orderNumbers)
  } else {
    //有订单号 是否一样
    if (orderNumberShow !== orderNumbers) {
      //订单号不一样
      sessionStorage.setItem('messageOrderShow', false)
      show.value = false
    } else {
      //订单号一样
      sessionStorage.setItem('messageOrderShow', true)
      show.value = true
    }
  }

  let message = 'Successfully Paid The Order'
  if (!!payment_intent && !show.value) {
    let messageHtml = `
    <span>${message}<span/>
    <img src="https://www.shareasale.com/sale.cfm?tracking=${orderNumbers}&amount=${num}&merchantID=126376&transtype=sale" width="1" height="1">
    `
    ElMessage({
      dangerouslyUseHTMLString: true,
      type: 'success',
      message: messageHtml,
    })
    sessionStorage.setItem('messageOrderNumber', orderNumbers)

    //谷歌光广告代码
    gtag('event', 'conversion', {
      send_to: 'AW-10926694714/Gw4fCJOP7cUDELrCoNoo',
      transaction_id: orderNumbers,
    })

    //广告埋点 Reddit Pixel
    rdt('track', 'Purchase')
  }
}

let updateCreditCard = async () => {
  //获取信用卡支付密钥
  let paymentIntentId = route.query.payment_intent
  //更换信用卡
  if (!!paymentIntentId) {
    try {
      await syncCreditCard({
        paymentIntentId,
      })
    } catch (error) {
      console.log(error, 'storeDetails')
    }
  }
}

const props = defineProps({
  storeCode: String,
  storeId: Number | String,
  storeData: {
    type: Array,
    default: () => [],
  },
})

watch(
  () => props.storeData,
  () => {
    getStore()
  }
)

const emit = defineEmits(['managerMyStore'])

let closeLoading = () => {
  loading.value = false
}

//#region 店铺列表
let storeList = ref([])
let stroeName = ref('')
let authorizeWord = ref('')

//获取店铺
let getStore = async () => {
  try {
    //   const { data } = await getStoreList();
    // storeList.push(...props.storeData, {
    //   name: "Manage My Store",
    //   id: 0,
    //   code: "Manage My Store",
    // });
    let manageStore = [
      {
        name: 'Manage My Store',
        id: 0,
        code: 'Manage My Store',
      },
    ]
    storeList.value = [...props.storeData, ...manageStore]
    storeList.value.forEach((item) => {
      if (item.code === props.storeCode) {
        stroeName.value = item.name
        authorizeWord.value = item.is_authorize_word
        platform.value = item?.platform?.name
      }
    })
    // loading.value = false;
  } catch (error) {
    ElMessage.error(error)
  }
}

//#endregion

//设置店铺

let itemCode = ref(null)
let platform = ref(null)
let setStoreName = async (item) => {
  if (item.code === 'Manage My Store') {
    emit('managerMyStore')
  } else {
    platform.value = item?.platform?.name
    stroeName.value = item.name
    itemCode.value = item.code
    authorizeWord.value = item.is_authorize_word
    sessionStorage.setItem('setStoreCode', item.code)
    //设置默认店铺
    try {
      await setDefaultStore({
        storeCode: item.code,
      })
    } catch (error) {
      ElMessage.error(error)
    }
  }
}

//创建订单
let createOrderShow = ref(false)
let createOrder = () => {
  createOrderShow.value = true
}

let close = (e) => {
  createOrderShow.value = false
  updateState.value = false
}

let billingMethodShow = ref(false)
let sum = ref('0.00')
let orderNumbers = ref('')
let closePopup = (e, v) => {
  sum.value = e
  orderNumbers.value = v
  billingMethodShow.value = true
  createOrderShow.value = false
}

let updateState = ref(false)
let updateList = () => {
  createOrderShow.value = false
  billingMethodShow.value = false
  updateState.value = true
}

//跳转产品
let addProduct = () => {
  // 获取 token
  let token
  if (process.env.VUE_APP_CUSTOM_ENV === 'development') {
    token = process.env.VUE_APP_TOKEN
  } else {
    token = Store.get('diyToken')
  }
  window.location.href =
    process.env.VUE_APP_URL_HOME + `/products?diyToken=${token}`
}

let productHead = ref('')

//成品导出
let handleCommand = async (e) => {
  //店铺code
  const storeCode = sessionStorage.getItem('setStoreCode')
  //designID
  const designId = productHead.value.getDesignId()
  try {
    const { data } = await getDesignerProductExportUrl({
      designId,
      type: e, //类型：1当前页，2所有
      storeCode,
    })
    window.location.href = data?.info
  } catch (error) {
    ElMessage.error(error || 'Serve Error')
  }
}

//#region 订单导入
let file = ref('')
let uploadMaterial = () => {
  document.querySelector('#inputFile').click()
}

let fileLoading = ref(false)
let getFileData = async (e) => {
  fileLoading.value = true
  let file = e.target.files[0]
  const storeCode = sessionStorage.getItem('setStoreCode')
  try {
    const data = await importOrder(file, storeCode)
    ElMessage.success(data?.message)
    updateState.value = !updateState.value
  } catch (error) {
    //错误信息
    updateState.value = !updateState.value
    if (
      !!error?.error &&
      Array.isArray(error?.error) &&
      error?.error.length > 0
    ) {
      ElMessageBox.alert(
        `<strong style='color:red;font-width:600;font-size:18px;'>${
          error.message
        }:</strong>
      <ol>${error?.error.map((e) => `<li>${e}</li>`)}</ol>`,
        'Error Message',
        {
          showConfirmButton: false,
          dangerouslyUseHTMLString: true,
        }
      ).catch(() => {
        return
      })
    } else {
      ElMessage.error(error)
    }

    fileLoading.value = false
  }
  e.target.value = ''
  fileLoading.value = false
}
//#endregion

//#region 订单导出
let time = ref('')
let exportOrder = () => {
  time.value.focus()
}

let datePicker = ref([])
let datePickerLoading = ref(false)
let datePickerChange = async (e) => {
  if (!!e) {
    const storeCode = sessionStorage.getItem('setStoreCode')
    datePickerLoading.value = true
    try {
      const { data } = await exportLogisticsUrl({
        startTime: e[0] / 1000,
        endTime: e[1] / 1000,
        storeCode,
      })
      window.location.href = data?.info
    } catch (error) {
      ElMessage.error(error)
      datePickerLoading.value = false
    }
    datePickerLoading.value = false
  }
}

//#endregion
</script>

<template>
  <div class="product_box" v-loading="loading">
    <div class="w1120">
      <!-- 店铺名称 -->
      <div class="title_box">
        <el-dropdown trigger="click">
          <span class="store">
            {{ stroeName }}
            <i class="iconfont icon-xiangxiajiantou"></i>
          </span>
          <span class="active">{{ authorizeWord }}</span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="item in storeList"
                :key="item.id"
                @click="setStoreName(item)"
              >
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div class="setting">
          <router-link
            class="setting_link"
            :to="`/store/settings/${itemCode || storeCode}?state=1`"
          >
            Settings
          </router-link>
        </div>
      </div>

      <!-- 按钮 -->

      <div class="btn_add" v-show="activeName === 'order'">
        <input
          type="file"
          style="display: none"
          ref="file"
          id="inputFile"
          @change="getFileData"
          accept=".xlsx"
        />

        <el-button type="info" @click="uploadMaterial" :loading="fileLoading"
          >Import orders</el-button
        >
        <el-date-picker
          type="datetimerange"
          ref="time"
          v-model="datePicker"
          @change="datePickerChange"
        />
        <el-button type="info" @click="exportOrder" :loading="datePickerLoading"
          >Export orders</el-button
        >
        <el-button type="primary" @click="createOrder">Create order</el-button>
      </div>
      <div class="btn_add" v-show="activeName === 'product'">
        <el-dropdown trigger="click" @command="handleCommand">
          <el-button type="info"> Export product </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="1"
                >Export current page</el-dropdown-item
              >
              <el-dropdown-item command="2"
                >Export all product</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button type="primary" @click="addProduct">Add product </el-button>
        <el-button type="primary" @click="createOrder">Create order</el-button>
      </div>
      <el-row>
        <el-col>
          <el-tabs v-model="activeName">
            <el-tab-pane label="My products" name="product">
              <ProductsTop
                :storeId="storeId"
                :storeCode="storeCode"
                :itemCode="itemCode"
                @closeLoading="closeLoading"
                ref="productHead"
                :platform="platform"
              />
            </el-tab-pane>
            <el-tab-pane label="My orders" name="order">
              <OrderList
                v-if="activeName === 'order'"
                :storeCode="storeCode"
                :itemCode="itemCode"
                @createOrder="createOrder"
                :createOrderShow="createOrderShow"
                :updateState="updateState"
              />
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>

  <!-- //创建订单 -->
  <CreateOrder
    v-model="createOrderShow"
    @input="close"
    :itemCode="itemCode"
    :storeCode="storeCode"
    @closePopup="closePopup"
  />

  <BillingMethod
    v-model="billingMethodShow"
    :sum="sum"
    :orderNumbers="orderNumbers"
    @updateList="updateList"
    @close="billingMethodShow = $event"
  />
</template>

<style lang="scss" scoped>
@import '@/views/myAccount/account.scss';

:deep(.el-date-editor) {
  width: 10px;
  height: 30px;
  opacity: 0;
  padding: 0;
}
.el-dropdown {
  margin-right: 10px;
}
.product_box {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  font-family: PingFang SC;
  display: flex;
  min-height: calc(100vh - 65px);
  flex-direction: column;
  .w1120 {
    position: relative;
    padding: 50px 0 40px 0;
    width: 1120px;
    margin: 0 auto;
  }
  .title_box {
    margin-bottom: 23px;
    display: flex;
    justify-content: space-between;
    .store {
      font-size: 36px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
    }
    .active {
      // line-height: 42px;
      margin-left: 38px;
      color: rgba(0, 0, 0, 0.65);
    }
    .setting {
      line-height: 42px;
      text-align: right;
      user-select: none;
      cursor: pointer;
      &_link {
        text-decoration: underline;
        color: rgba(0, 0, 0, 0.65);
        &:hover {
          color: #ff2379;
        }
      }
    }
  }
  .btn_add {
    width: 450px;
    position: absolute;
    right: 0px;
    top: 100px;
    z-index: 10;
    text-align: right;
    display: flex;
  }
}
</style>
